import React, { useState, useEffect } from 'react';
import { TextField, Button, FormControl, FormControlLabel, FormGroup, Checkbox } from '@mui/material';
import { useAccounts } from '../Shared/ApiServiceHook';

const getDefault = () => {
    return { customerID: 1, accountNumber: 1000, groupAccountNumber: 1000, description: '', isRF1140Balance: false, isRF1140Result: false };
}

const AccountsForm = ({ id, onClose }) => {
    const [entity, setEntity] = useState(getDefault());
    const api = useAccounts();

    useEffect(() => {
        const fetchBankAccount = async () => {
            if (id && id !== 0) {
                const account = await api.getUnique(id);
                setEntity(account);
            } else {
                setEntity(getDefault());
            }
        };

        fetchBankAccount();
    }, [id]);

    const handleCreateOrUpdate = async (ev) => {
        ev.preventDefault();
        
        if (id) {
            await api.update(entity);
        } else {
            await api.create(entity);
        }

        onClose();
    };

    return (
        <React.Fragment>
            <form onSubmit={(event) => handleCreateOrUpdate(event)} className="samport-form">
                <FormControl>
                    <TextField
                        label="Account Number"
                        type="number"
                        fullWidth
                        value={entity.accountNumber}
                        onChange={(e) => setEntity({ ...entity, accountNumber: e.target.value })}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        label="Group Account Number"
                        type="number"
                        fullWidth
                        value={entity.groupAccountNumber}
                        onChange={(e) => setEntity({ ...entity, groupAccountNumber: e.target.value })}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        label="Description"
                        fullWidth
                        value={entity.description}
                        onChange={(e) => setEntity({ ...entity, description: e.target.value })}
                    />
                </FormControl>
                <FormGroup>
                <FormControlLabel control={<Checkbox checked={entity.isRF1140Balance} onChange={(e) => setEntity({ ...entity, isRF1140Balance: e.target.checked })} />} label="Is RF1140 Balance" />
                </FormGroup>
                <FormGroup>
                <FormControlLabel control={<Checkbox checked={entity.isRF1140Result} onChange={(e) => setEntity({ ...entity, isRF1140Result: e.target.checked })} />} label="Is RF1140 Result" />
                </FormGroup>

                <Button type="submit">{ id ? 'Update' : 'Create' }</Button>
                </form>
        </React.Fragment>
    );
};

export default AccountsForm;
