import React, { useState, useEffect } from 'react';
import { Box, Paper, Stack, Button } from '@mui/material';
import { useAccountingEventInstances } from '../Shared/ApiServiceHook';
import EventSelector from '../Shared/EventSelector';
import BankTransactionsTable from './BankTransactionsTable';

const RegisterEvents = () => {
    const [evParams, setEvParams] = useState(0);
    const [bts, setBts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [ts, setTs] = useState(0);

    const api = useAccountingEventInstances();

    const bankTransactionsSelected = (ts) => {
        setBts(ts);
    }

    const evParamsUpdated = (evParams) => {
        setEvParams(evParams);
    }

    const createEvents = async () => {
        setIsLoading(true);

        console.log('CounterpartID: ');
        console.log(evParams.counterpartID);

        const payload = {
            mode: evParams.mode,
            accountingEventID: evParams.accEvID,
            occuredDate: evParams.occuredDate,
            counterpartID: evParams.counterpartID !== 0 ? evParams.counterpartID : null,
            bankTransactionIDs: []
        };

        bts.map(b => {
            payload.bankTransactionIDs.push(b);
        });

        console.log('Sending');
        console.log(JSON.stringify(payload));
        await api.createWithEvBank(payload);

        const newTs = ts + 1;
        setTs(newTs);
        setIsLoading(false);
    }

    const renderProgress = () => {
        if (isLoading) {
            return (<progress />);
        }
        return;
    }

    return (
        <Stack direction="column" spacing={2}>
            <p>Ev: {evParams.accEvID}, BtsCount: {bts.length}</p>
            {renderProgress()}
            <Paper elevation={3} sx={{ p: 2 }}>
                <Stack direction="row" spacing={2}>
                    <EventSelector onParamsChanged={evParamsUpdated} />
                    <Button onClick={createEvents}>Create events</Button>
                </Stack>
            </Paper>
            <Paper elevation={3} sx={{ p: 2}}>
                <BankTransactionsTable onBankTransactionsSelected={bankTransactionsSelected} ts={ts} />
            </Paper>
        </Stack>
    )
}

export default RegisterEvents;