import React, { useState, useEffect } from 'react';

import { useAccountingEventSchedules } from '../Shared/ApiServiceHook';

import { Paper, IconButton, Grid, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as dayjs from 'dayjs';

import GenericModal from '../Shared/GenericModal';

import AccountingEventSchedulesForm from '../Forms/AccountingEventSchedulesForm';

const AccountingEventSchedulesTable = ({ onUpdated, accountingEventId }) => {
    const [openModal, setOpenModal] = useState(false);
    const [currentId, setCurrentId] = useState(null);

    const [schedules, setSchedules] = useState([]);

    const api = useAccountingEventSchedules();

    useEffect(() => {
        fetch();
    }, [accountingEventId]);

    const fetch = async () => {
        const sch = await api.getWP(accountingEventId);
        setSchedules(sch);
    };

    const handleClose = () => {
        setOpenModal(false);
        fetch();
    };

    const handleOpen = (sch) => {
        setCurrentId(sch?.accountingEventScheduleID);
        setOpenModal(true);
    }

    const deleteItem = async (sch) => {
        await api.execDelete(sch);

        fetch();
    }

    const getFrequency = (freq) => {
        if (freq == 1) {
            return "Yearly";
        } else if (freq == 12) {
            return "Monthly";
        }

        return "-";
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3}><Grid container justifyContent="flex-end"><Button onClick={() => handleOpen(null)} >Create</Button></Grid></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>StartDate</TableCell>
                        <TableCell>Frequency</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        schedules.map(sch => (
                            <TableRow onDoubleClick={() => handleOpen({ sch })} key={sch.accountingEventScheduleID}>
                                <TableCell>{dayjs(sch.startDate).format('YYYY-MM-DD')}</TableCell>
                                <TableCell>{getFrequency(sch.frequency)}</TableCell>
                                <TableCell><IconButton onClick={() => deleteItem(sch.accountingEventScheduleID)}><DeleteIcon /></IconButton></TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>

            <GenericModal open={openModal} handleClose={handleClose} title={'Add or edit'}>
                <AccountingEventSchedulesForm id={currentId} accountingEventId={accountingEventId} onClose={handleClose} />
            </GenericModal>
        </TableContainer>
    );
};

export default AccountingEventSchedulesTable;
