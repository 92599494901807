import React, {useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBankAccounts, useBankTransactions } from '../Shared/ApiServiceHook';
import { formatDate, formatDecimal } from '../../services/formatUtils';
import { DataGrid, nbNO as dataGridNbNO } from '@mui/x-data-grid';
import {Divider, Typography, Stack, IconButton}  from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const BankTransactions = () => {
    const { id } = useParams();

    const [ts, setTs] = useState([]);
    const [acc, setAcc] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const bankTransactions_api = useBankTransactions();
    const bankAccounts_api = useBankAccounts();

    useEffect(() => {
        const getBankTransactions = async () => {
            const res = await bankTransactions_api.getByAccount(id);
            setTs(res);
        };

        const getBankAccount = async () => {
            const res = await bankAccounts_api.getUnique(id);

            setAcc(res);
        };
        
        getBankTransactions();
        getBankAccount();
    }, []);

    const deleteTransaction = async (row) => {
        console.log('deleting: ' + row.bankTransactionID);
        bankTransactions_api.execDelete(row.bankTransactionID);
    }

    const cols = [
        { field: 'bookDate', headerName: 'Date', width: '50px', flex: 1 , valueFormatter: (params) => {
            return formatDate(params.value);
        }},
        { field: 'amount', headerName: 'Amount', width: '50px', flex: 1, type: 'number', valueFormatter: (params) => {
            return formatDecimal(params.value);
        }},
        { field: 'description', headerName: 'Desc', width: '100px', flex: 3},
        { field: 'reference', headerName: 'Ref', width: '100px', flex: 2},
        {
            field: 'actions',
            headerName: '',
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <Stack direction='row'>
                    <IconButton onClick={() => deleteTransaction(params.row)} aria-label="view">
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ),
        },
    ];

    return (
        <div>
            <Typography element="h1">
                Account: {acc.description}
            </Typography>
            <Divider>Transactions</Divider>
            <div style={{ height: '700px', width: '100%' }}>
                <DataGrid
                    rowHeight={25}
                    rows={ts}
                    columns={cols}
                    getRowId={(r) => r.bankTransactionID}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 100 },
                        },
                    }}
                    pageSizeOptions={[50, 100]}
                    checkboxSelection
                    localeText={dataGridNbNO}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setSelectedRows(newRowSelectionModel);
                    }}
                    rowSelectionModel={selectedRows}
                />
                
                </div>
        </div>
    );
}

export default BankTransactions;