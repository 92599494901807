import { useUser } from '../../UserContext';
import { useApiService } from '../../services/apiService';

export const useReporting = () => {
    const api = useApiService();

    const userCtx = useUser();

    const getAccountsReport = async (year, detailed, mode) => {
        return await api.get(`/reporting/accounts?mode=${mode}&year=${year}${detailed ? "&detailed=true": ""}`);
    }

    return { getAccountsReport };
}