import React, {useState, useEffect} from 'react';
import CounterpartsForm from '../Forms/CounterpartsForm';
import {Stack, Paper, TableCell, TableContainer, Table, TableHead, TableBody, TableRow, IconButton } from '@mui/material';
import { useCounterparts } from '../Shared/ApiServiceHook';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';

const CounterpartsOverview = () => {
    const [entities, setEntities] = useState([]);
    const counterpartsApi = useCounterparts();

    const fetch = async () => {
        let f_cps = await counterpartsApi.get();

        setEntities(f_cps);
    }

    useEffect(() => {
        fetch();
    }, []);

    const handleSubmit = async () => {
        await fetch();
    }

    const handleDelete = async (c) => {
        await counterpartsApi.execDelete(c.counterpartID);

        await fetch();
    }

    return (
        <Stack spacing={2}>
            <Paper sx={{p:2}}>
                <CounterpartsForm onClose={handleSubmit} />
            </Paper>
            <Paper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Is Cost</TableCell>
                                <TableCell>Is Person</TableCell>
                                <TableCell>Is Section</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {entities.map(c => (
                                <TableRow key={c.counterpartID}>
                                    <TableCell>{c.counterpartName}</TableCell>
                                    <TableCell>{c.isCostCounterpart && <CheckIcon />}</TableCell>
                                    <TableCell>{c.isPerson && <CheckIcon />}</TableCell>
                                    <TableCell>{c.isSection && <CheckIcon />}</TableCell>
                                    <TableCell><IconButton onClick={() => handleDelete(c)}><DeleteIcon /></IconButton></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Stack>
    )
}

export default CounterpartsOverview;