import React, { useEffect, useState } from 'react';
import { useAccountingEvents, useAccounts, useCounterparts, useAccountingEventCounterparts } from '../Shared/ApiServiceHook';
import { useParams } from 'react-router-dom';
import { TextField, Button, FormControl, MenuItem, InputLabel, Grid, Paper, Stack, Typography, Divider, InputAdornment } from '@mui/material';
import { Select } from '@mui/material';
import GenericModal from '../Shared/GenericModal';

import AccountingEventCounterpartsTable from './AccountingEventCounterpartsTable';
import AccountingEventSchedulesTable from './AccountingEventSchedulesTable';
import AccountsForm from '../Accounts/AccountsForm';

const AccountingEventEdit = () => {
    const { id } = useParams();

    const api = useAccountingEvents();
    const accountsApi = useAccounts();
    const counterpartsApi = useCounterparts();
    const accEvCpsApi = useAccountingEventCounterparts();

    const [isLoading, setIsLoading] = useState(true);
    const [accountingEvent, setAccountingEvent] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [currentForm, setCurrentForm] = useState(null);
    const [currentFormId, setCurrentFormId] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [counterparts, setCounterparts] = useState([]);
    const [accEvCounterparts, setAccEvCounterparts] = useState([]);

    const fetch = async () => {
        const [accEv, accs, cps, accEvCps] = await Promise.all([
            api.getUnique(id),
            accountsApi.get(),
            counterpartsApi.get(),
            accEvCpsApi.getWP(id),
        ]);

        console.log(accEvCpsApi);

        setAccountingEvent(accEv);
        setAccounts(accs);
        setCounterparts(cps);
        setAccEvCounterparts(accEvCps);

        setIsLoading(false);
    };

    useEffect(() => {
        fetch();
    }, [id]);

    const fetchOtherData = async () => {
        const [accs, cps] = await Promise.all([
            accountsApi.get(),
            counterpartsApi.get(),
        ]);

        setAccounts(accs);
        setCounterparts(cps);
    }

    const handleClose = () => {
        setOpenModal(false);
        fetchOtherData();
    };

    const handleOpen = (type, id) => {
        setCurrentForm(type);
        setCurrentFormId(id);
        setOpenModal(true);
    }

    useEffect(() => {
        if (!isLoading) {
            updateAccountingEvent();
        }
    }, [accountingEvent.result_AccountID])

    const updateAccountingEvent = async () => {
        console.log('updating: ' + JSON.stringify(accountingEvent));
        await api.update(accountingEvent);
    }

    const getForm = (type, id) => {
        switch (type) {
            case 'Accounts':
                return <AccountsForm id={id} onClose={handleClose} />;
            default:
                return null;
        }
    }

    if (isLoading) {
        return (<p>loading...</p>);
    }

    return (
        <Stack direction="column" spacing={2}>
            <Paper sx={{ p: 2 }} elevation={3}>
                <Divider sx={{ mb: 4 }}><Typography variant="h4">Accounting Event</Typography></Divider>
                <form className="samport-form">
                    <FormControl>
                        <TextField
                            label="Description"
                            fullWidth
                            value={accountingEvent.description}
                            onChange={(e) => setAccountingEvent({ ...accountingEvent, description: e.target.value })}
                        />
                    </FormControl>
                    <FormControl>
                        <InputLabel id="ae-type-label">Type</InputLabel>
                        <Select
                            labelId="ae-type-label"
                            id="ae-type"
                            value={accountingEvent.accountingEventType}
                            label="Result Account"
                            onChange={(e) => setAccountingEvent({ ...accountingEvent, accountingEventType: e.target.value })}>
                            <MenuItem key={0} value={0}>NA</MenuItem>
                            <MenuItem key={1} value={1}>Cost</MenuItem>
                            <MenuItem key={2} value={2}>Income</MenuItem>
                            <MenuItem key={3} value={3}>Rent</MenuItem>
                            <MenuItem key={22} value={22}>Custom</MenuItem>
                            <MenuItem key={23} value={23}>Free</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <TextField
                            label="Amount"
                            fullWidth
                            type="Number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">NOK</InputAdornment>,
                            }}
                            value={accountingEvent.amount}
                            onChange={(e) => setAccountingEvent({ ...accountingEvent, amount: e.target.value })}
                        />
                    </FormControl>
                    <Button onClick={updateAccountingEvent}>Save</Button>
                </form>
            </Paper>
            <Paper sx={{ p: 2 }} elevation={3}>
                <Divider sx={{ mb: 4 }}><Typography variant="h4">Result account</Typography></Divider>

                <Grid item xs={12}>
                    <InputLabel id="result-account-label">Result Account</InputLabel>
                    <Select
                        labelId="result-account-label"
                        id="result-account"
                        value={accountingEvent.result_AccountID}
                        label="Result Account"
                        onChange={(e) => setAccountingEvent({ ...accountingEvent, result_AccountID: e.target.value })}>
                        {accounts.map(a => (
                            <MenuItem key={a.accountID} value={a.accountID}>{a.accountNumber}: {a.description}</MenuItem>
                        ))}
                        <MenuItem key="0" value={undefined}></MenuItem>
                    </Select>
                    <Button onClick={() => handleOpen('Accounts', accountingEvent.result_AccountID)}>Edit</Button>
                    <Button onClick={() => handleOpen('Accounts', null)}>Create</Button>
                </Grid>
            </Paper>

            <Paper sx={{ p: 2 }} elevation={3}>
                <Divider sx={{ mb: 4 }}><Typography variant="h4">Counterpart Linked</Typography></Divider>
                <AccountingEventCounterpartsTable onUpdated={() => fetch()} accountingEventId={id} counterparts={counterparts} accEvs={accEvCounterparts} />
            </Paper>

            <Paper sx={{ p: 2 }} elevation={3}>
                <Divider sx={{ mb: 4 }}><Typography variant="h4">Scheduling</Typography></Divider>
                <AccountingEventSchedulesTable onUpdated={() => fetch()} accountingEventId={id} />
            </Paper>

            <GenericModal open={openModal} handleClose={handleClose} title={'Add or edit'}>
                {getForm(currentForm, currentFormId)}
            </GenericModal>
        </Stack>
    );
};

export default AccountingEventEdit;
