import React, { useState, useEffect } from 'react';
import { TextField, Button, FormControl } from '@mui/material';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UsersForm = ({ onClose }) => {
    const [entity, setEntity] = useState({ customerID: 1, email: '', password: '' });

    const handleCreateOrUpdate = async (ev) => {
        ev.preventDefault();

        const response = await axios.post(`${BASE_URL}/authorization/create-user`, entity);
        
        onClose();
    };

    return (
        <React.Fragment>
            <form onSubmit={(event) => handleCreateOrUpdate(event)} className="samport-form">
                <FormControl>
                    <TextField
                        label="Email"
                        fullWidth
                        value={entity.email}
                        onChange={(e) => setEntity({ ...entity, email: e.target.value })}
                    />
                </FormControl>

                <FormControl>
                    <TextField
                        label="Password"
                        fullWidth
                        value={entity.password}
                        onChange={(e) => setEntity({ ...entity, password: e.target.value })}
                    />
                </FormControl>

                <Button type="submit">Create</Button>
                </form>
        </React.Fragment>
    );
};

export default UsersForm;
