export function formatCurrency(amount, digits = 2) {
    return new Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK', minimumFractionDigits: digits, maximumFractionDigits: digits }).format(amount);
}

export function formatDecimal(amount, digits) {
    if (!digits && digits !== 0) {
        digits = 2;
    }

    return new Intl.NumberFormat('nb-NO', { style: 'decimal', minimumFractionDigits: digits, maximumFractionDigits: digits, currency: 'NOK' }).format(amount);
}

export function formatDate(date) {
    if (!date) {
        return '';
    }
    
    return new Intl.DateTimeFormat('nb-NO', {
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit'
    }).format(new Date(date));
}

export function formatSize(size) {
    if (size < 1000) {
        return formatDecimal(size, 0) + "B";
    }

    if (size < 1000000) {
        return formatDecimal(size / 1000, 0) + "KB";
    }

    return formatDecimal(size / 1000000, 0) + "MB";
}