import React, { useState } from 'react';
import { Stack, TextField, Button } from '@mui/material';

const BankTransactionsTableFilter = ({ onApplyFilter }) => {
    const [filter, setFilter] = useState({ searchText: '' });
    
    const handleApply = () => {
        onApplyFilter(filter);
    }

    return (
        <React.Fragment>
                <Stack direction="row" spacing={2}>
                    <TextField 
                        label="Search text"
                        value={filter.searchText}
                        onChange={(e) => setFilter({...filter, searchText: e.target.value})}
                        />
                    <Button onClick={handleApply}>Apply</Button>
                </Stack>
        </React.Fragment>
    )
}

export default BankTransactionsTableFilter;