import React, { useState, useEffect } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UserRolesForm = ({onClose, userId, username, userRoles }) => {
    const [roleMap, setRoleMap] = useState({});
    const [roles, setRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        fetchRoles();
    }, []);

    useEffect(() => {
        const initialRoleMap = {};
        roles.forEach(role => {
            initialRoleMap[role] = userRoles.includes(role);
        });

        setRoleMap(initialRoleMap);
    }, [userId, isLoading]);

    const fetchRoles = async () => {
        const response = await axios.get(`${BASE_URL}/api/userroleactions/roles`);

        if (response.data.data) {
            setRoles(response.data.data);
        }

        setIsLoading(false);
    }

    const handleRoleChange = (role) => {
        setRoleMap(prevRoleMap => ({
            ...prevRoleMap,
            [role]: !prevRoleMap[role]
        }));
    };

    const submit = async () => {
        const selectedRoles = Object.keys(roleMap).filter(role => roleMap[role]);

        const payload = {
            userId: userId,
            roleNames: selectedRoles
        };

        await axios.post(`${BASE_URL}/api/userroleactions/update-roles`, payload);
        onClose();
    }

    if (isLoading) {
        return;
    }

    return (
        <React.Fragment>
            <p>{userId}</p>
            <p>{username}</p>
            <p>{userRoles.join(",")}</p>

            {roles.map(role => (
                <FormGroup key={role}>
                    <FormControlLabel control={<Checkbox checked={roleMap[role] || false} onChange={() => handleRoleChange(role)} />} label={role} />
                </FormGroup>
            ))}
            <Button onClick={submit}>Submit</Button>
            <Button onClick={onClose}>Close</Button>
        </React.Fragment>
    );
}

export default UserRolesForm;