import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DocumentPreviewPdf = ({ fileUrl }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    const containerRef = useRef(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        updateScale();
    };

    const updateScale = () => {
        if (containerRef.current) {
            const containerWidth = containerRef.current.clientWidth;
            const desiredWidth = containerWidth > 600 ? 600 : containerWidth; // Adjust this value as needed
            setScale(desiredWidth / 600); // Assume 600 is the base width for a PDF page
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateScale);
        return () => window.removeEventListener('resize', updateScale);
    }, []);

    useEffect(() => {
        updateScale();
    }, [numPages]); // Update scale once the number of pages is known and on window resize

    const goToPrevPage = () => {
        setPageNumber(prevPage => Math.max(prevPage - 1, 1));
    };

    const goToNextPage = () => {
        setPageNumber(prevPage => Math.min(prevPage + 1, numPages));
    };

    return (
        <div ref={containerRef}>
            <div>
                <button onClick={goToPrevPage} disabled={pageNumber <= 1}>Previous</button>
                <button onClick={goToNextPage} disabled={pageNumber >= numPages}>Next</button>
                <p>Page {pageNumber} of {numPages}</p>
            </div>
            <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} scale={scale} />
            </Document>
        </div>
    );
};

export default DocumentPreviewPdf;
