import React, { useState, useEffect } from 'react';
import { TextField, Button, FormControl, FormControlLabel, FormGroup, Checkbox } from '@mui/material';
import { useCounterparts } from '../Shared/ApiServiceHook';

const CounterpartsForm = ({ id, onClose }) => {
    const [entity, setEntity] = useState({ customerID: 1, counterpartName: '', isSection: false, isCostCounterpart: true, isPerson: false });
    const api = useCounterparts();

    useEffect(() => {
        const fetch = async () => {
            if (id) {
                const ent = await api.getUnique(id);
                setEntity(ent);
            }
        };

        fetch();
    }, [id]);

    const handleCreateOrUpdate = async (ev) => {
        ev.preventDefault();

        if (id) {
            await api.update(entity);
        } else {
            await api.create(entity);
        }

        onClose();
    };

    return (
        <React.Fragment>
            <form onSubmit={(event) => handleCreateOrUpdate(event)} className="samport-form">
                <FormControl>
                    <TextField
                        label="Counterpart Name"
                        fullWidth
                        value={entity.counterpartName}
                        onChange={(e) => setEntity({ ...entity, counterpartName: e.target.value })}
                    />
                </FormControl>

                <FormGroup>
                <FormControlLabel control={<Checkbox checked={entity.isSection} onChange={(e) => setEntity({ ...entity, isSection: e.target.checked })} />} label="Is Section" />
                </FormGroup>

                <FormGroup>
                <FormControlLabel control={<Checkbox checked={entity.isCostCounterpart} onChange={(e) => setEntity({ ...entity, isCostCounterpart: e.target.checked })} />} label="Is Cost Counterpart" />
                </FormGroup>

                <FormGroup>
                <FormControlLabel control={<Checkbox checked={entity.isPerson} onChange={(e) => setEntity({ ...entity, isPerson: e.target.checked })} />} label="Is Person" />
                </FormGroup>

                <Button type="submit">Create</Button>
                </form>
        </React.Fragment>
    );
};

export default CounterpartsForm;
