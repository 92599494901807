import React, { useState, useEffect } from 'react';
import { TextField, Button, FormControl, FormControlLabel, FormGroup, Checkbox } from '@mui/material';
import { useAccountingEvents } from '../Shared/ApiServiceHook';

const AccountingEventsCreate = ({ onClose }) => {
    const [entity, setEntity] = useState({ customerID: 1, description: '', isIncome: false, periodisizeAsDefault: false, accountingEventType: 1 });
    const api = useAccountingEvents();

    const handleCreate = async (ev) => {
        ev.preventDefault();

        await api.create(entity);

        onClose();
    };

    return (
        <React.Fragment>
            <form onSubmit={(event) => handleCreate(event)} className="samport-form">
                <FormControl>
                    <TextField
                        label="Description"
                        fullWidth
                        value={entity.description}
                        onChange={(e) => setEntity({ ...entity, description: e.target.value })}
                    />
                </FormControl>
                
                <Button type="submit">Create</Button>
                </form>
        </React.Fragment>
    );
};

export default AccountingEventsCreate;
