import React, { useState, useEffect } from 'react';

import { Box, Button } from '@mui/material';

import { useDocuments } from '../Shared/ApiServiceHook';
import DocumentPreviewPdf from './DocumentPreviewPdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const DocumentPreview = ({ documentID, onUrlSet }) => {
    const [docUrlResponse, setDocUrlResponse] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const docsApi = useDocuments();

    const fetch = async () => {
        const f_doc = await docsApi.getDocumentWithUrl(documentID);

        if (onUrlSet) {
            onUrlSet(f_doc.url);
        }

        setDocUrlResponse(f_doc);
        setIsLoading(false);
    }

    useEffect(() => {
        fetch();
    }, [documentID]);

    if (isLoading) {
        return (<p>Loading...</p>);
    }

    const renderPreview = () => {
        const contentType = docUrlResponse.contentType;
        const url = docUrlResponse.url;

        switch (contentType) {
            case "image/jpeg":
            case "image/png":
                return <img src={url} alt="Document" style={{ width: '100%', height: 'auto' }} />;
            case "application/pdf":
                return (
                    <DocumentPreviewPdf fileUrl={url} />
                );
            case "text/plain":
                return <iframe src={url} style={{ width: '100%', height: '500px' }} title="Text File"></iframe>;
            default:
                return <p>Unsupported file type</p>;
        }
    }
    
    return (
        <React.Fragment>
            <div sx={{width: "100%"}}>
                <a href={docUrlResponse.url} target="_blank" rel="noopener noreferrer"><Button fullWidth variant="contained" color="primary">Open document in new tab</Button></a>
                {renderPreview()}
            </div>
        </React.Fragment>
    )
}

export default DocumentPreview;