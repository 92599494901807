import React, { useState, useEffect } from 'react';
import { TextField, Button, FormControl, FormControlLabel, FormGroup, Checkbox } from '@mui/material';
import { useBankAccounts } from '../Shared/ApiServiceHook';

const BankAccountsCreate = ({ accountId, onClose }) => {
    const [newAccount, setNewAccount] = useState({ description: '', bankAccountNumber: '', isMain: false, customerID: 1 });
    const bankAccountsApi = useBankAccounts();

    useEffect(() => {
        const fetchBankAccount = async () => {
            if (accountId) {
                const account = await bankAccountsApi.getUnique(accountId);
                setNewAccount(account);
            }
        };

        fetchBankAccount();
    }, [accountId]);

    const handleCreateOrUpdate = async (ev) => {
        ev.preventDefault();

        if (accountId) {
            await bankAccountsApi.update(accountId, newAccount);
        } else {
            await bankAccountsApi.create(newAccount);
        }

        onClose();
    };

    return (
        <React.Fragment>
            <form onSubmit={(event) => handleCreateOrUpdate(event)} className="samport-form">
                <FormControl>
                    <TextField
                        label="Description"
                        fullWidth
                        value={newAccount.description}
                        onChange={(e) => setNewAccount({ ...newAccount, description: e.target.value })}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        label="Bank Account Number"
                        fullWidth
                        value={newAccount.bankAccountNumber}
                        onChange={(e) => setNewAccount({ ...newAccount, bankAccountNumber: e.target.value })}
                    />
                </FormControl>
                
                <FormGroup>
                <FormControlLabel control={<Checkbox checked={newAccount.isMain} onChange={(e) => setNewAccount({ ...newAccount, isMain: e.target.checked })} />} label="Is Main" />
                </FormGroup>

                <Button type="submit">{accountId ? 'Update' : 'Create'}</Button>
                </form>
        </React.Fragment>
    );
};

export default BankAccountsCreate;
