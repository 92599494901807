import React, { useState, useEffect } from 'react';
import { Stack, Paper, Button, FormControl, FormControlLabel, Checkbox, InputLabel, Select, MenuItem } from '@mui/material';
import { DateField } from '@mui/x-date-pickers/DateField';
import { useAccountingEventInstances, useAccountingEvents } from '../Shared/ApiServiceHook';
import dayjs, { Dayjs } from 'dayjs';

const GenerateScheduled = () => {
    const [generateParams, setGenerateParams] = useState({ dateFrom: dayjs(), dateTo: dayjs(), overwrite: false, accountingEventID: 0 });
    const [accEvs, setAccEvs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const accEvApi = useAccountingEvents();
    const api = useAccountingEventInstances();

    const generate = async () => {
        setIsLoading(true);

        await api.generateScheduled(generateParams);

        setIsLoading(false);
    }

    useEffect(() => {
        fetchAccEvs();
    }, [])

    const fetchAccEvs = async () => {
        const f_accEvs = await accEvApi.get();
        f_accEvs.push({accountingEventID: 0, description: '---All---'});

        setAccEvs(f_accEvs);
    }

    const renderLoading = () => {
        if (isLoading) {
            return (
                <progress />
            );
        }

        return;
    }

    return (
        <Stack spacing={2} direction="column">
            {renderLoading()}
            <Paper sx={{p:2}} elevation={3}>
                <Stack direction="row" spacing={2}>
                    <FormControl>
                        <DateField
                            label="From Date"
                            format="DD-MM-YYYY"
                            value={generateParams.dateFrom}
                            onChange={(e) => setGenerateParams({ ...generateParams, dateFrom: e })}
                        />
                    </FormControl>
                    <FormControl>
                        <DateField
                            label="To Date"
                            format="DD-MM-YYYY"
                            value={generateParams.dateTo}
                            onChange={(e) => setGenerateParams({ ...generateParams, dateTo: e })}
                        />
                    </FormControl>
                    <FormControl>
                    <InputLabel id="sel-ae-label">Event</InputLabel>
                    <Select
                        labelId="sel-ae-label"
                        id="sel-ae-type"
                        value={generateParams.accountingEventID}
                        displayEmpty
                        label="Event"
                        onChange={(e) => setGenerateParams({ ...generateParams, accountingEventID: e.target.value })}>
                        {accEvs.map(a => (
                            <MenuItem key={a.accountingEventID} value={a.accountingEventID}>{a.description}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                    <FormControlLabel control={<Checkbox checked={generateParams.overwrite} onChange={(e) => setGenerateParams({ ...generateParams, overwrite: e.target.checked })} />} label="Overwrite" />
                    <Button onClick={generate}>Generate</Button>
                </Stack>
            </Paper>
        </Stack>
    )
}

export default GenerateScheduled;