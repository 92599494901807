import React, { useState, useEffect } from 'react';
import { Stack, FormControl, InputLabel, Select, MenuItem, TextField, Button, Paper, Typography } from '@mui/material';
import { useAccountingEventInstances, useAccountingEvents, useCounterparts } from '../Shared/ApiServiceHook';
import PostingsEditTable from '../Shared/Postings/PostingsEditTable';
import { DateField } from '@mui/x-date-pickers/DateField';
import * as dayjs from 'dayjs';

const getDefaultState = () => {
    return {accountingEventID: 0, eventDate: dayjs(), counterpartID: 0, description: '', note: '', postings: []};
}

const RegisterFreeEvents = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [accEvs, setAccEvs] = useState([]);
    const [payload, setPayload] = useState(getDefaultState());
    const [counterparts, setCounterparts] = useState([]);

    const accEvInstApi = useAccountingEventInstances();
    const accEvApi = useAccountingEvents();
    const counterpartsApi = useCounterparts();

    const fetch = async () => {
        const f_accEvs = await accEvApi.get();
        const f_accEvs_custom = f_accEvs.filter(f => f.accountingEventType == 23 || f.accountingEventType == 22);

        setAccEvs(f_accEvs_custom);

        const f_counterparts = await counterpartsApi.get();
        setCounterparts(f_counterparts);
    }

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        setIsLoading(false);
    }, [counterparts])

    const handlePostingsUpdate = (postings) => {
        setPayload({...payload, postings: postings});
    }

    const saveChanges = async () => {
        setIsLoading(true);

        console.log('Posting');
        console.log(JSON.stringify(payload));
        if (await accEvInstApi.createFree(payload)) 
        {
            setPayload(getDefaultState());
        }

        setIsLoading(false);
    }

    if (isLoading) {
        return (<progress />);
    }

    return (
        <Stack direction="column" spacing={2}>
            <Paper sx={{p:2}}>
                <Typography>Create a free event</Typography>
            </Paper>
            <FormControl>
                <InputLabel id="sel-ae-label">Event</InputLabel>
                <Select
                    labelId="sel-ae-label"
                    id="sel-ae-type"
                    value={payload.accountingEventID}
                    displayEmpty
                    label="Result Account"
                    onChange={(e) => setPayload({...payload, accountingEventID: e.target.value})}>
                    {accEvs.map(a => (
                        <MenuItem key={a.accountingEventID} value={a.accountingEventID}>{a.description}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl>
                <InputLabel id="sel-cp-label">Counterpart</InputLabel>
                <Select
                    labelId="sel-cp-label"
                    id="sel-cp"
                    value={payload.counterpartID}
                    displayEmpty
                    label="Result Account"
                    onChange={(e) => setPayload({...payload, counterpartID: e.target.value})}>
                    {counterparts.map(cp => (
                        <MenuItem key={cp.counterpartID} value={cp.counterpartID}>{cp.counterpartName}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl>
                <DateField
                    label="Event date"
                    format="DD-MM-YYYY"
                    value={payload.eventDate}
                    onChange={(e) => setPayload({ ...payload, eventDate: e })}
                />
            </FormControl>

            <FormControl>
                <TextField
                    label="Description"
                    fullWidth
                    value={payload.description}
                    onChange={(e) => setPayload({...payload, description: e.target.value})}
                />
            </FormControl>
            <FormControl>
                <TextField
                    label="Note"
                    fullWidth 
                    value={payload.note}
                    onChange={(e) => setPayload({...payload, note: e.target.value})}
                />
            </FormControl>

            <Button fullWidth onClick={() => saveChanges()}>Create</Button>

            <PostingsEditTable postingsUpdated={handlePostingsUpdate} />
        </Stack>
    )
}

export default RegisterFreeEvents;