import React, { useState } from 'react';

import { useAccountingEventCounterparts } from '../Shared/ApiServiceHook';

import { Paper, IconButton, Grid, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as dayjs from 'dayjs';

import GenericModal from '../Shared/GenericModal';

import AccountingEventCounterpartsForm from '../Forms/AccountingEventCounterpartsForm';

const AccountingEventCounterpartsTable = ({ onUpdated, accountingEventId, counterparts, accEvs }) => {
    const [openModal, setOpenModal] = useState(false);
    const [currentId, setCurrentId] = useState(null);

    const accEvCpApi = useAccountingEventCounterparts();

    const handleClose = () => {
        setOpenModal(false);
        onUpdated();
    };

    const handleOpen = (accEv) => {
        setCurrentId(accEv?.accountingEventCounterpartID);
        setOpenModal(true);
    }

    const deleteAccEvCp = async (accEvCp) => {
        await accEvCpApi.execDelete(accEvCp);

        onUpdated();
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3}><Grid container justifyContent="flex-end"><Button onClick={() => handleOpen(null)} >Create</Button></Grid></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>StartDate</TableCell>
                        <TableCell>Counterpart</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        accEvs.map(ev => (
                            <TableRow onDoubleClick={() => handleOpen({ ev })} key={ev.accountingEventCounterpartID}>
                                <TableCell>{dayjs(ev.startDate).format('YYYY-MM-DD')}</TableCell>
                                <TableCell>{counterparts.find(c => c.counterpartID == ev.counterpartID)?.counterpartName || "-"}</TableCell>
                                <TableCell><IconButton onClick={() => deleteAccEvCp(ev.accountingEventCounterpartID)}><DeleteIcon /></IconButton></TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>

            <GenericModal open={openModal} handleClose={handleClose} title={'Add or edit'}>
                <AccountingEventCounterpartsForm id={currentId} accountingEventId={accountingEventId} onClose={handleClose} />
            </GenericModal>
        </TableContainer>
    );
};

export default AccountingEventCounterpartsTable;
