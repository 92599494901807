import React, { useEffect, useState } from 'react';
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';
import Dashboard from './Dashboard';
import Login from './Shared/Login';
import MainLayout from './Shared/MainLayout';
import { Routes, Route } from 'react-router-dom';
import BankTransactionImport from './BankAccounts/BankTransactionImport';
import BankAccounts from './BankAccounts/BankAccounts';
import BankTransactions from './BankAccounts/BankTransactions';
import RegisterEvents from './RegisterEvents/RegisterEvents';
import AccountingEvents from './AccountingEvents/AccountingEvents';
import AccountingEventEdit from './AccountingEvents/AccountingEventEdit';
import SectionsOverview from './Sections/SectionsOverview';
import SectionEdit from './Sections/SectionEdit';
import SectionRentMultiple from './Sections/SectionRentMultiple';
import GenerateScheduled from './GenerateScheduled/GenerateScheduled';
import AccountingEventInstanceReport from './AccountingEventInstances/AccountingEventInstanceReport';
import RegisterFreeEvents from './RegisterFreeEvents/RegisterFreeEvents';
import CounterpartsOverview from './Counterparts/CounterpartsOverview';
import DocumentsOverview from './Documents/DocumentsOverview';
import DocumentUpload from './Documents/DocumentUpload';
import RegisterAttachments from './RegisterAttachments/RegisterAttachments';
import AccountsOverview from './Accounts/AccountsOverview';
import UsersOverview from './Users/UsersOverview';
import ReportAccountingEvents from './Reporting/ReportAccountingEvents';
import ReportBalance from './Reporting/ReportBalance';
import ReportResult from './Reporting/ReportResult';

const SamportApp = () => {
    const { user, setUser, tryFetchUser } = useUser();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkUser = async () => {
            if (!user || !user.isAuthenticated) {
                const updUser = await tryFetchUser(setUser);
                
                if (!updUser || !updUser.isAuthenticated) {
                    console.log('No user');

                    navigate('/login');
                }
            } 
            
            setIsLoading(false);
        }

        checkUser();
    }, [user, navigate]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Routes>
            <Route path="/" element={<MainLayout header="Oversikt"><Dashboard /></MainLayout>} /> 

            <Route path="/reporting/balance" element={<MainLayout header="Balanse"><ReportBalance /></MainLayout> } />
            <Route path="/reporting/result" element={<MainLayout header="Resultat"><ReportResult /></MainLayout> } />
            <Route path="/reporting/events" element={<MainLayout header="Events"><ReportAccountingEvents readMode={true} /></MainLayout>} />


            <Route path="/users/overview" element={<MainLayout header="Users"><UsersOverview /> </MainLayout>} />
            <Route path="/bankaccounts" element={<MainLayout header="Bank Accounts"><BankAccounts /></MainLayout>} />
            <Route path="/bankaccounts/:id/transactionimport" element={<MainLayout header="Bank transaction import" />}>
                <Route index element={<BankTransactionImport />} />
            </Route>
            <Route path="/bankaccounts/:id/transactions" element={<MainLayout header="Bank Transactions" />}>
                <Route index element={<BankTransactions />} />
            </Route>
            <Route path="/accountingevents" element={<MainLayout header="Accounting events"><AccountingEvents /></MainLayout>} />
            <Route path="/accountingevents/:id" element={<MainLayout header="Accounting Event" />}>
                <Route index element={<AccountingEventEdit />} />
            </Route>

            <Route path="/registerevents" element={<MainLayout header="Register"><RegisterEvents /></MainLayout>} />
            <Route path="/register/registerfree" element={<MainLayout header="Register Free"><RegisterFreeEvents /></MainLayout>} />
            <Route path="/register/attachments" element={<MainLayout header="Attachments"><RegisterAttachments /></MainLayout>} />

            <Route path="/generate-scheduled" element={<MainLayout header="Generate Scheduled"><GenerateScheduled /></MainLayout>} />

            <Route path="/sections" element={<MainLayout header="Sections"><SectionsOverview /></MainLayout>} />
            <Route path="/sections/:id" element={<MainLayout header="Section" />}>
                <Route index element={<SectionEdit />} />
            </Route>

            <Route path="/counterparts" element={<MainLayout header="Counterparts"><CounterpartsOverview /></MainLayout>} />

            <Route path="/sectionrents" element={<MainLayout header="Section rents"><SectionRentMultiple /></MainLayout>} />
            
            <Route path="/events/events" element={<MainLayout header="Events"><AccountingEventInstanceReport /></MainLayout>} />

            <Route path="/documents" element={<MainLayout header="Documents"><DocumentsOverview></DocumentsOverview></MainLayout>} />
            <Route path="/documents/upload" element={<MainLayout header="Document upload"><DocumentUpload></DocumentUpload></MainLayout>} />
            <Route path="/accounts" element={<MainLayout header="Accounts"><AccountsOverview /></MainLayout>} />

            <Route path="/login" element={<Login />} />
        </Routes>
    );
};

export default SamportApp;