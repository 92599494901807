import React, { useState, useEffect } from 'react';
import { Button, FormControl, Grid, InputLabel, Select, MenuItem } from '@mui/material';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs, { Dayjs } from 'dayjs';

import { useAccountingEventCounterparts, useCounterparts } from '../Shared/ApiServiceHook';

import CounterpartsForm from './CounterpartsForm';

const AccountingEventCounterpartsForm = ({ id, accountingEventId, onClose }) => {
    const [entity, setEntity] = useState({ counterpartID: 0, accountingEventId: accountingEventId, startDate: dayjs("2015-12-31") });
    const [showCp, setShowCp] = useState(false);

    const api = useAccountingEventCounterparts();
    const counterpartsApi = useCounterparts();

    const [counterparts, setCounterparts] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            if (id) {
                const ent = await api.getUnique(id);
                setEntity(ent);
            }
        };

        fetchCounterparts();
        fetch();
    }, [id]);

    const fetchCounterparts = async () => {
        const counterparts_fetch = await counterpartsApi.get();
        setCounterparts(counterparts_fetch);
    }

    const handleCreateOrUpdate = async (ev) => {
        ev.preventDefault();

        console.log(entity);

        if (id) {
            console.log('updating');
            await api.update(entity);
        } else {
            console.log('creating');
            await api.create(entity);
        }

        onClose();
    };

    const handleCounterpartsFormClose = () => {
        setShowCp(false);
        fetchCounterparts();
    }

    const getCounterpartsForm = () => {
        if (!showCp) {
            return (
                <Button onClick={() => setShowCp(true)}>Create Counterpart</Button>
            )
        }

        return (
            <div>
                <CounterpartsForm onClose={handleCounterpartsFormClose} />
            </div>
        )
    }

    return (
        <React.Fragment>
            <div>
                {getCounterpartsForm()}
            </div>

            <form onSubmit={(event) => handleCreateOrUpdate(event)} className="samport-form">
                <FormControl>
                    <Grid item xs={12}>                    
                        <InputLabel id="accounting-event-counterpart-label">Counterpart</InputLabel>
                        <Select
                            fullWidth
                            labelId="accounting-event-counterpart-label"
                            id="accounting-event-counterpart"
                            value={entity.counterpartID ?? 0}
                            label="Counterpart"
                            onChange={(e) => setEntity({ ...entity, counterpartID: e.target.value})}>
                                {counterparts.map(c => (
                                    <MenuItem key={c.counterpartID} value={c.counterpartID}>{c.counterpartName}</MenuItem>
                                ))}
                                <MenuItem key="0" value={0}></MenuItem>
                        </Select>
                    </Grid>
                </FormControl>
                <FormControl>
                    <DateField
                        label="StartDate"
                        format="DD-MM-YYYY"
                        value={entity.startDate}
                        onChange={(e) => setEntity({ ...entity, startDate: e })}
                    />
                </FormControl>

                <Button type="submit">{id ? 'Update link' : 'Link counterpart'}</Button>
            </form>
        </React.Fragment>
    );
};

export default AccountingEventCounterpartsForm;
