import React, { useState, useEffect } from 'react';
import { TextField, Button, FormControl, List, ListItem, Typography } from '@mui/material';
import { useSections } from '../Shared/ApiServiceHook';

const SectionsSimpleForm = ({ id, onClose }) => {
    const [entity, setEntity] = useState({ customerID: 1, sectionNumberStart: 0, sectionNumberEnd: 0});
    const [info, setInfo] = useState({ minSectionNumber: 0, maxSectionNumber: 0 });
    const api = useSections();

    useEffect(() => {
        const fetchInfo = async () => {
            const inf = await api.fetchInfo();
            setInfo(inf);
        }

        fetchInfo();
    }, []);

    const handleCreate = async (ev) => {
        ev.preventDefault();

        await api.createMultiple(entity);

        onClose();
    };

    return (
        <React.Fragment>
            <form onSubmit={(event) => handleCreate(event)} className="samport-form">
                <List>
                    <ListItem>
                        Min: {info.minSectionNumber}
                    </ListItem>
                    <ListItem>
                        Max: {info.maxSectionNumber}
                    </ListItem>
                </List>
                <FormControl>
                    <TextField
                        label="Section number start"
                        type="number"
                        fullWidth
                        value={entity.sectionNumberStart}
                        onChange={(e) => setEntity({ ...entity, sectionNumberStart: e.target.value })}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        label="Section number end"
                        type="number"
                        fullWidth
                        value={entity.sectionNumberEnd}
                        onChange={(e) => setEntity({ ...entity, sectionNumberEnd: e.target.value })}
                    />
                </FormControl>

                <Button type="submit">Create</Button>
                </form>
        </React.Fragment>
    );
};

export default SectionsSimpleForm;
