import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAccountingEvents } from '../Shared/ApiServiceHook';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AccountingEventsCreate from './AccountingEventsCreate';
import GenericModal from '../Shared/GenericModal';

const AccountingEvents = () => {
    const [entities, setEntities] = useState([]);
    const api = useAccountingEvents();
    const [openModal, setOpenModal] = useState(false);

    const fetch = async () => {
        
        const f_unsorted = await api.get();
        const f_entities = f_unsorted.sort((a, b) => a.description.localeCompare(b.description));

        const filtered = [];

        f_entities.map(e => {
            if (e.accountingEventType <= 3) {
                filtered.push(e);
            }
        })
        setEntities(filtered);
    };

    useEffect(() => {
        fetch();
    }, []);

    const handleClose = () => {
        setOpenModal(false);
        fetch();
    };

    const handleOpen = (id) => {
        setOpenModal(true);
    }

    if (!entities) {
        return (<p>loading...</p>);
    }
    return (
        <div>
            <Button onClick={() => handleOpen(null)}>Create</Button>
            {entities.map(entity => (
                <Card key={entity.accountingEventID} sx={{ minWidth: 275, marginBottom: 2 }}>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {entity.description}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" component={RouterLink} to={`/accountingevents/${entity.accountingEventID}`}>Edit</Button>
                    </CardActions>
                </Card>
            ))}
            <GenericModal open={openModal} handleClose={handleClose} title={'Add Accounting event'}>
                <AccountingEventsCreate onClose={handleClose} />
            </GenericModal>
        </div>
    );
};

export default AccountingEvents;
