import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Table, TableHead, TableRow, TableBody, TableCell, Box, Paper, TableContainer, Button, Grid, Stack } from '@mui/material';
import { useSections, useSectionRents } from '../Shared/ApiServiceHook';
import GenericModal from '../Shared/GenericModal';
import SectionsSimpleForm from './SectionsSimpleForm';

import EditIcon from '@mui/icons-material/Edit';

const SectionsOverview = () => {
    const [sections, setSections] = useState([]);
    const [sectionRents, setSectionRents] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const api = useSections();
    const rentApi = useSectionRents();

    useEffect(() => {
        fetch();
    }, []);

    const fetch = async () => {
        const res = await api.get();
        setSections(res);

        const rents = await rentApi.getAppliedRents();
        setSectionRents(rents);
    }

    const openCreateForm = () => {
        setShowModal(true);
    }

    const handleClose = () => {
        fetch();
        setShowModal(false);
    }

    const pad = (n) => {
        if (n < 10) {
            return "0" + n;
        }

        return n;
    }

    const editSection = (id) => {

    }

    return (
        <Box>
            <Paper sx={{ p: 2 }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={4}><Grid container justifyContent="flex-end"><Button onClick={openCreateForm}>Create</Button></Grid></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Section #</TableCell>
                                <TableCell>Current rent</TableCell>
                                <TableCell>Distribution</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sections.map(s => (
                                <TableRow key={s.sectionID}>
                                    <TableCell>{pad(s.sectionNumber)}</TableCell>
                                    <TableCell>{sectionRents.find(r => r.sectionID == s.sectionID)?.rent ?? "-"}</TableCell>
                                    <TableCell>{s.sectionDistributionShare}</TableCell>
                                    <TableCell>
                                        <Stack direction="row">
                                            <Button component={RouterLink} to={`/sections/${s.sectionID}`}><EditIcon /></Button>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <GenericModal open={showModal} handleClose={handleClose} title={'Add'}>
                <SectionsSimpleForm onClose={handleClose} />
            </GenericModal>
        </Box>
    );
}

export default SectionsOverview;