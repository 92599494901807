import React, { useEffect, useState } from 'react';
import { useAccountingEventInstances, useAccountingEventInstanceReports } from '../Shared/ApiServiceHook';
import GenericModal from '../Shared/GenericModal';
import { DataGrid, nbNO as dataGridNbNO } from '@mui/x-data-grid';
import { formatCurrency, formatDate } from '../../services/formatUtils';
import AccountingSummaryView from './AccountingSummaryView';
import { Typography, Stack, Card, CardContent, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import AccountingEventInstanceView from './AccountingEventInstanceView';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';

const getDefaultFilters = () => {
    return { hideAttached: false, counterpartSearch: '', typeSearch: '', year: 2023  };
}

const AccountingEventInstanceReport = ({ key, givenFilters = getDefaultFilters(), onSelectionChanged = (sel) => {}, readMode = false }) => {
    const [report, setReport] = useState([]);
    const [instance, setInstance] = useState({});
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [filters, setFilters] = useState(givenFilters);

    const api = useAccountingEventInstanceReports();
    const instanceApi = useAccountingEventInstances();

    const [selectedRows, setSelectedRows] = useState([]);

    const fetch = async () => {
        let f_report = await api.list({});

        f_report.accountingEventInstances = f_report.accountingEventInstances.filter(r => {
            if (filters.hideAttached && (r.attachments.length > 0 || r.attachmentOk)) {
                return false;
            }

            if (filters.counterpartSearch && filters.counterpartSearch !== '' && !r.counterpartText.toLowerCase().includes(filters.counterpartSearch.toLowerCase()))
            {
                return false;
            }

            if (filters.typeSearch && filters.typeSearch !== '' && !r.accountingEventText.toLowerCase().includes(filters.typeSearch.toLowerCase()))
            {
                return false;
            }

            if (filters.year && filters.year > 0 && filters.year != new Date(r.eventDate).getFullYear() && filters.year != new Date(r.settledDate).getFullYear()) {
                return false;
            }

            return true;
        });

        setReport(f_report);
    };

    useEffect(() => {
        fetch();
    }, [key, filters]);

    useEffect(() => {
        onSelectionChanged(selectedRows);
    }, [selectedRows]);

    const handleClose = () => {
        setModalIsOpen(false);
    };

    const handleOpenModal = (inst) => {
        setInstance(inst);
        setModalIsOpen(true);
    }

    const deleteInstance = async (inst) => {
        await instanceApi.execDelete(inst.accountingEventInstanceID);
        await fetch();
    }

    const deleteSelected = async () => {
        await instanceApi.execDeleteList(selectedRows);
        await fetch();
    }

    const cols = [
        {
            field: 'actions',
            headerName: '',
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <Stack direction='row'>
                    <IconButton onClick={() => handleOpenModal(params.row)} aria-label="view">
                        <VisibilityIcon />
                    </IconButton>
                </Stack>
            ),
        },
        { field: 'accountingEventInstanceID' },
        { field: 'eventDate', headerName: 'Date', width: '50px', flex: 1 , valueFormatter: (params) => {
            return formatDate(params.value);
        }},
        { field: 'accountingEventModeText', headerName: 'Mode', width: '100px', flex: 1},
        { field: 'accountingEventText', headerName: 'Type', width: '100px', flex: 1},
        { field: 'accountingEventInstanceText', headerName: 'Item', width: '100px', flex: 2},
        { field: 'counterpartText', headerName: 'Counterpart', width: '100px', flex: 2},
        { field: 'attachments', headerName: 'Attachments', width: '50px', flex: 1, valueFormatter: (params) => {
            return params && params.value && params.value.length;
        }},
        { field: 'amount', headerName: 'Amount', width: '50px', flex: 1, type: 'number', valueFormatter: (params) => {
            return formatCurrency(params.value);
        }},
        {
            field: 'actions2',
            headerName: '',
            sortable: false,
            width: 100,
            renderCell: (params) => {
                if (readMode) {
                    return "";
                }
                else {
                    return (
                        <Stack direction='row'>
                            <IconButton onDoubleClick={() => deleteInstance(params.row)} aria-label="view">
                                <DeleteIcon />
                            </IconButton>
                        </Stack>
                    )
                }
            },
        },
    ];

    if (!report?.accountingEventInstances) {
        return (<p>loading...</p>);
    }
    return (
        <Stack spacing={2}>
            <Stack spacing={2}>
            <FormControl>
                    <InputLabel id="ae-type-label">Year</InputLabel>
                    <Select
                        labelId="ae-type-label"
                        id="ae-type"
                        label="Type"
                        defaultValue={0}
                        displayEmpty
                        value={filters.year}
                        onChange={(e) => setFilters({ ...filters, year: e.target.value })}>
                        <MenuItem key={0} value={0}><em>All</em></MenuItem>
                        <MenuItem key={2022} value={2022}>2022</MenuItem>
                        <MenuItem key={2023} value={2023}>2023</MenuItem>
                        <MenuItem key={2024} value={2024}>2024</MenuItem>
                    </Select>
                    
                </FormControl>
            </Stack>
            <AccountingSummaryView summary={report.summary} asExpandable={true} />

            <DataGrid
                        rowHeight={25}
                        rows={report.accountingEventInstances}
                        columns={ cols }
                        getRowId={(r) => r.accountingEventInstanceID}
                        columnVisibilityModel= {{
                            accountingEventInstanceID: false 
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 100 },
                            },
                        }}
                        pageSizeOptions={[50, 100]}
                        checkboxSelection
                        localeText={dataGridNbNO}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setSelectedRows(newRowSelectionModel);
                        }}
                        rowSelectionModel={selectedRows}
                    />

            <Card>
                <CardContent>
                    <Button onClick={() => deleteSelected()}>Delete selection</Button>
                </CardContent>
            </Card>

            <GenericModal open={modalIsOpen} handleClose={handleClose} width={"90vw"} height={"90vh"}>
                <AccountingEventInstanceView instance={instance} readOnly={false} />
                {/* onClose={handleClose}  */}
            </GenericModal>
        </Stack>
    );
};

export default AccountingEventInstanceReport;
