import React, { useState, useEffect } from 'react';
import { TextField, Button, FormControl } from '@mui/material';
import { DateField } from '@mui/x-date-pickers/DateField';
import { useSectionRents } from '../Shared/ApiServiceHook';
import dayjs, { Dayjs } from 'dayjs';

const SectionRentsForm = ({ id, sectionID, onClose }) => {
    const [entity, setEntity] = useState({ sectionID: sectionID, startDate: dayjs("2015-12-31"), rent: 0});
    const api = useSectionRents();

    const handleCreate = async (ev) => {
        ev.preventDefault();

        console.log('creating:');
        console.log(entity);
        await api.create(entity);

        onClose();
    };

    return (
        <React.Fragment>
            <form onSubmit={(event) => handleCreate(event)} className="samport-form">
                <FormControl>
                    <DateField
                        label="Start Date"
                        format="DD-MM-YYYY"
                        value={entity.startDate}
                        onChange={(e) => setEntity({ ...entity, startDate: e })}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        label="Rent"
                        type="number"
                        fullWidth
                        value={entity.rent}
                        onChange={(e) => setEntity({ ...entity, rent: e.target.value })}
                    />
                </FormControl>

                <Button type="submit">Create</Button>
            </form>
        </React.Fragment>
    );
};

export default SectionRentsForm;
