import React, {useState, useEffect } from 'react';
import { Card, CardContent, Typography, Stack, List, ListItem, Table, TableHead, TableRow, TableCell, TableBody, TextareaAutosize, Button, FormControlLabel, Checkbox } from '@mui/material';
import { DataGrid, nbNO as dataGridNbNO } from '@mui/x-data-grid';
import { formatCurrency, formatDate } from '../../services/formatUtils';
import AccountingSummaryView from './AccountingSummaryView';
import DocumentsViewer from '../Documents/DocumentsViewer';
import { useAccountingEventInstances } from '../Shared/ApiServiceHook';

const AccountingEventInstanceView = ({ instance, readOnly=true }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [editedInstance, setEditedInstance] = useState({accountingEventInstanceId: instance.accountingEventInstanceID, note: instance.note, attachmentOk: instance.attachmentOk });

    const api = useAccountingEventInstances();

    useEffect(() => {
        setEditedInstance({...editedInstance, accountingEventInstanceId: instance.accountingEventInstanceID});
    }, [instance]);

    const postingCols = [
        { field: 'postingID' },
        { field: 'postingDate', headerName: 'Date', width: '50px', flex: 1 , valueFormatter: (params) => {
            return formatDate(params.value);
        }},
        { field: 'debitAccountNumber', headerName: 'Debit', width: '100px', flex: 3},
        { field: 'debitAccountText', headerName: 'Debit text', width: '100px', flex: 2},
        { field: 'creditAccountNumber', headerName: 'Credit', width: '100px', flex: 2},
        { field: 'creditAccountText', headerName: 'Credit text', width: '100px', flex: 2},
        { field: 'amount', headerName: 'Amount', width: '50px', flex: 1, type: 'number', valueFormatter: (params) => {
            return formatCurrency(params.value);
        }},
    ]

    const updateEvent = async () => {
        await api.updateInfo(editedInstance);
    }

    const renderNote = () => {
        if (readOnly) {
            if (instance.note && instance.note != '') {
                return (
                    <Stack direction="row" spacing={2} sx={{width: '100%'}}>
                        <Typography variant='h5'>Notat:</Typography>
                        <TextareaAutosize value={instance.note} disabled style={{ width: '100%', flexGrow: 1, fontSize: '1.2em' }} />
                    </Stack>
                );
            }

            return;
        }
        
        return (
            <Stack direction="row" spacing={2} sx={{width: '100%'}}>
                <Typography variant='h5'>Notat</Typography>
                <TextareaAutosize 
                    value={editedInstance.note} 
                    onChange={(e) => setEditedInstance({...editedInstance, note: e.target.value })} 
                    style={{ width: '100%', flexGrow: 1, fontSize: '1.2em' }}
                    minRows={2}
                />
                <FormControlLabel
                    value="top"
                    control={<Checkbox checked={editedInstance.attachmentOk} onChange={(e) => setEditedInstance({...editedInstance, attachmentOk: e.target.checked })} />}
                    label="AttachmentOK"
                    labelPlacement="top"
                />
                <Button onClick={updateEvent}>Update</Button>
            </Stack>
        );
    }

    return (
        <React.Fragment>
            <Stack spacing={2}>
                <Typography variant='h4'>{instance.accountingEventText} {formatDate(instance.eventDate)} - Nøkkeldata</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Type</TableCell>
                            <TableCell>Motpart</TableCell>
                            <TableCell>Oppstod</TableCell>
                            <TableCell>Betalt</TableCell>
                            <TableCell>Beskrivelse banktransaksjon</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell><em>{instance.accountingEventText}</em></TableCell>
                            <TableCell>{instance.counterpartText}</TableCell>
                            <TableCell>{formatDate(instance.eventDate)}</TableCell>
                            <TableCell>{formatDate(instance.settledDate)}</TableCell>
                            <TableCell>{instance.bankTransactionText}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                {renderNote()}
                {/* <AccountingSummaryView summary={instance.summary} asExpandable={false} /> */}
                <Typography variant='h4'>Posteringer</Typography>
                <DataGrid
                    rowHeight={25}
                    rows={instance.postings}
                    columns={ postingCols }
                    getRowId={(r) => r.postingID}
                    columnVisibilityModel= {{
                        postingID: false 
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 100 },
                        },
                    }}
                    pageSizeOptions={[50, 100]}
                    checkboxSelection
                    localeText={dataGridNbNO}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setSelectedRows(newRowSelectionModel);
                    }}
                    rowSelectionModel={selectedRows}
                        />

                <Typography variant='h4'>Dokumenter</Typography>
                <DocumentsViewer documentIds={instance.attachments.map(d => d.documentID)} hideFilters={true} />
            </Stack>
        </React.Fragment>
    );
};

export default AccountingEventInstanceView;
