import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { isTokenExpiringSoon } from './services/tokenUtils';

const initialUserState = {
    username: null,
    isAuthenticated: false,
    isRegistered: false,
    roles: [],
    token: null,
    refreshToken: null
};

const UserContext = createContext(null);

let userFetchPromise = null;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(initialUserState);

    const login = async (credentials) => {
        try {
            const response = await axios.post(`${apiBaseUrl}/authorization/login`, credentials);
            
            if (response.data && response.data.token) {
                const tokens =  {token: response.data.token, refreshToken: response.data.refreshToken};

                localStorage.setItem("samportid", JSON.stringify(tokens));

                const currentUser = await getCurrentUser(tokens);

                if (currentUser) {
                    setUser(currentUser);

                    if (currentUser.isAuthenticated) {
                        return true;
                    }
                }
            }
        } catch(error) {
            console.error('Login error: ', error);

            return false;
        }
    }

    const refreshToken = async (refreshToken) => {
        try {
            console.log('Refreshing token');
            console.log(user);

            console.log('With url: ' + apiBaseUrl);
            const response = await axios.post(`${apiBaseUrl}/authorization/refresh-token`, { refreshToken: refreshToken });

            if (response.data && response.data.token) {
                const responseTokens = {token: response.data.token, refreshToken: response.data.refreshToken};

                localStorage.setItem("samportid", JSON.stringify(responseTokens));

                setUser({...user, token: responseTokens.token, refreshToken: responseTokens.refreshToken });
                console.log('Token refresh successful');

                return responseTokens;
            }
            else {
                console.log('Token refresh failed...');
            }
        }
        catch (error) {
            console.error('Failed refreshToken');
        }
    }

    const getCurrentUser = async (tokens) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${tokens.token}`,
                }
            };

            const response = await axios.get(`${apiBaseUrl}/authorization/currentuser`, config);

            if (response.data && response.data.email) {
                console.log('Got user response');
                const currentUser = {
                    username: response.data.email,
                    isAuthenticated: true,
                    isRegistered: true,
                    roles: response.data.roles,
                    token: tokens.token,
                    refreshToken: tokens.refreshToken
                };
                
                return currentUser;
            }
            else {
                console.log('No user response');
            }
        } catch(error) {
            console.error('Login error: ', error);
            return null;
        }
    }

    const tryFetchUser = async () => {
        if (user.isAuthenticated) {
            return user;
        }

        if (userFetchPromise) {
            return userFetchPromise;
        }

        userFetchPromise = (async () => {
            let tokens = JSON.parse(localStorage.getItem('samportid')); 
            
            if (tokens && tokens.token) {
                if (isTokenExpiringSoon(tokens.token)) {
                    tokens = await refreshToken(tokens.refreshToken);
                }

                let currentUser = null;

                if (tokens) {
                    currentUser = await getCurrentUser(tokens);
                }

                if (currentUser) {
                    console.log('Setting user');
                    setUser(currentUser);
                    userFetchPromise = null;
                    return currentUser;
                }
            }

            userFetchPromise = null;
            return user;
        })();

        return userFetchPromise;
    }

    const getToken = async () => {
        if (isTokenExpiringSoon(user.token)) {
            await refreshToken(user.refreshToken);
        }

        return user.token;
    }

    useEffect(() => {
        tryFetchUser();
    }, []);

    const contextValue = {
        user, 
        setUser, 
        login,
        tryFetchUser,
        getToken,
    };

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};


export const useUser = () => useContext(UserContext);
