import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, Typography, Stack } from '@mui/material';
import DocumentsViewer from './DocumentsViewer';

const DocumentsOverview = () => {
    return (
        <React.Fragment>
            <Stack spacing={2}>
                <RouterLink to="/documents/upload">
                    <Card>
                        <CardContent>
                            <Typography>Upload files</Typography>
                        </CardContent>
                    </Card>
                </RouterLink>
                <DocumentsViewer />
            </Stack>
        </React.Fragment>
    );
}

export default DocumentsOverview;