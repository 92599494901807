import axios from 'axios';
import { useUser } from '../UserContext';

const BASE_URL = process.env.REACT_APP_API_BASE_URL + "/api";

export const useApiService = () => {
    const usr = useUser();
    
    const get = async (path) => {
        try {
            const token = await usr.getToken();

            console.log('Get with token: ');
            console.log(token);

            const response = await axios.get(`${BASE_URL}${path}`, { headers: { Authorization: `Bearer ${token}`}});
    
            return response.data.data;
        } catch(error) {
            handleApiError(error);
            return false;
        }
    };
    
    const put = async(path, payload) => {
        try {
            const token = await usr.getToken();
    
            const response = await axios.put(`${BASE_URL}${path}`, payload, { headers: { Authorization: `Bearer ${token}`}});
    
            return response.data.data;
        } catch(error) {
            console.log(error);
            handleApiError(error);
            return false;
        }
    }
    
    const post = async (path, payload, options = {}) => {
        // const token = await usr.getToken();
    
        // options = {...options, headers: {...options.headers, Authorization: `Bearer ${token}`}};
        const response = await postRaw(path, payload, options);
    
        if (response) {
            return response.data.data;
        }
    
        return false;
    };
    
    const postRaw = async (path, payload, options = {}) => {
        try {
            const token = await usr.getToken();
    
            const headers = {
                ...options.headers,
                Authorization: `Bearer ${token}`,
            };
    
            const config = {
                headers,
                ...options
            };
    
            let response = null;
            
            response = await axios.post(`${BASE_URL}${path}`, payload, config);
    
            console.log('postresponse');
            console.log(response);
    
            return response;
        } catch(error) {
            handleApiError(error);
            return false;
        }
    }
    
    const execDelete = async (path) => {
        try {
            const token = await usr.getToken();
    
            const response = await axios.delete(`${BASE_URL}${path}`, { headers: { Authorization: `Bearer ${token}`}});
    
            return response;
        } catch(error) {
            handleApiError(error);
            return false;
        }
    }
    
    const handleApiError = (error) => {
        if (error.response) {
            console.error('Error data:', error.response.data);
            console.error('Error status:', error.response.status);
            console.error('Error headers:', error.response.headers);
        } else if (error.request) {
            console.error('Error request:', error.request);
        } else {
            console.error('Error message:', error.message);
        }
    };

    return { get, put, post, postRaw, execDelete };
}