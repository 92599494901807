import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { formatCurrency, formatDecimal } from '../../services/formatUtils';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const AccountingSummaryView = ({summary, asExpandable}) => {
    const renderTable = () => (
        <div>
            <Card>
                <CardContent>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Account</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell align='right'>Debit</TableCell>
                                    <TableCell align='right'>Credit</TableCell>
                                    <TableCell align='right'>Net</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {summary.items.map(i => (
                                    <TableRow key={i.accountID}>
                                        <TableCell>{i.accountNumber}</TableCell>
                                        <TableCell>{i.accountDescription}</TableCell>
                                        <TableCell align='right'>{ formatDecimal(i.debitAmount, 0) }</TableCell>
                                        <TableCell align='right'>{ formatDecimal(i.creditAmount, 0) }</TableCell>
                                        <TableCell align='right'>{ formatDecimal(i.netAmount, 0)}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={2} align='right'>Total</TableCell>
                                    <TableCell align='right'>{ formatCurrency(summary.debitAmount)}</TableCell>
                                    <TableCell align='right'>{ formatCurrency(summary.creditAmount)}</TableCell>
                                    <TableCell align='right'>{ formatCurrency(summary.netAmount)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </div>
    );

    if (asExpandable) {
        return (
            <Accordion>
                <AccordionSummary>
                    <Typography>Account summary: {formatCurrency(summary.debitAmount)} + { formatCurrency(summary.creditAmount)} = {formatCurrency(summary.netAmount)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {renderTable()}
                </AccordionDetails>
            </Accordion>
        );
    }

    return (
        <React.Fragment>
            {renderTable()}
        </React.Fragment>
    );
};

export default AccountingSummaryView;
