import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useBankAccounts } from '../Shared/ApiServiceHook';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import BankAccountsCreate from './BankAccountsCreate';
import GenericModal from '../Shared/GenericModal';

const BankAccounts = () => {
    const [bankAccounts, setBankAccounts] = useState([]);
    const bankAccountsApi = useBankAccounts();
    const [openModal, setOpenModal] = useState(false);
    const [editAccountId, setEditAccountId] = useState(null);

    const fetchBankAccounts = async () => {
        const accounts = await bankAccountsApi.get();
        setBankAccounts(accounts);
    };

    useEffect(() => {
        fetchBankAccounts();
    }, []);

    const handleClose = () => {
        setOpenModal(false);
        fetchBankAccounts();
    };

    const handleOpen = (id) => {
        setEditAccountId(id);
        setOpenModal(true);
    }

    if (!bankAccounts) {
        return (<p>loading...</p>);
    }
    return (
        <div>
            <Button onClick={() => handleOpen(null)}>Create</Button>
            {bankAccounts.map(account => (
                <Card key={account.bankAccountID} sx={{ minWidth: 275, marginBottom: 2 }}>
                    <CardContent onClick={() => handleOpen(account.bankAccountID)} sx={{ cursor: 'pointer' }}>
                        <Typography variant="h5" component="div">
                            {account.description}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" component={RouterLink} to={`/bankaccounts/${account.bankAccountID}/transactionimport`}>Import</Button>
                        <Button size="small" component={RouterLink} to={`/bankaccounts/${account.bankAccountID}/transactions`}>View transactions</Button>
                    </CardActions>
                </Card>
            ))}
            <GenericModal open={openModal} handleClose={handleClose} title={editAccountId ? 'Edit Bank Account' : 'Add Bank Account'}>
                <BankAccountsCreate accountId={editAccountId} onClose={handleClose} />
            </GenericModal>
        </div>
    );
};

export default BankAccounts;
