import { useApiService } from '../../services/apiService';

export const useAccounts = () => {
    const api = useApiService();

    const get = async () => {
        return await api.get("/Accounts");
    }

    const getUnique = async (id) => {
        return await api.get(`/Accounts/${id}`);
    }

    const create = async (ev) => {
        return await api.post(`/Accounts/`, ev);
    }

    const update = async (ev) => {
        return await api.put(`/Accounts/`, ev);
    }

    return { get, getUnique, create, update };
}

export const useAccountingEvents = () => {
    const api = useApiService();

    const get = async () => {
        return await api.get("/accountingevents");
    }

    const getUnique = async (id) => {
        return await api.get(`/accountingevents/${id}`);
    }

    const create = async (ev) => {
        return await api.post(`/accountingevents/`, ev);
    }

    const update = async (ev) => {
        return await api.put(`/accountingevents/`, ev);
    }

    return { get, getUnique, create, update };
}

export const useAccountingEventCounterparts = () => {
    const api = useApiService();

    const getWP = async (accountingEventId) => {
        return await api.get(`/AccountingEventCounterpartActions/wp?accountingEventID=${accountingEventId}`);
    }

    const getUnique = async (id) => {
        return await api.get(`/AccountingEventCounterparts/${id}`);
    }

    const create = async (ev) => {
        return await api.post(`/AccountingEventCounterparts/`, ev);
    }

    const update = async (ev) => {
        return await api.put(`/AccountingEventCounterparts/`, ev);
    }

    const execDelete = async (id) => {
        return await api.execDelete(`/AccountingEventCounterparts/${id}`);
    }

    return { getWP, getUnique, create, update, execDelete };
}

export const useAccountingEventInstances = () => {
    const api = useApiService();
    
    const get = async () => {
        return await api.get("/accountingeventinstances");
    } 

    const createWithEvBank = async (payload) => {
        return await api.post('/accountingeventinstanceactions/CreateWithEvBank', payload);
    }

    const createFree = async (payload) => {
        return await api.post('/accountingeventinstanceactions/createfree', payload);
    }

    const updateInfo = async(payload) => {
        return await api.post('/accountingeventinstanceactions/updateinfo', payload);
    }

    const generateScheduled = async (payload) => {
        return await api.post('/accountingeventinstanceactions/generateScheduled', payload);
    }

    const execDelete = async (id) => {
        return await api.execDelete(`/accountingeventinstances/${id}`);
    }

    const execDeleteList = async (payload) => {
        return await api.post('/accountingeventinstanceactions/deleteList', payload);
    }

    return { get, createWithEvBank, createFree, updateInfo, generateScheduled, execDelete, execDeleteList };
}

export const useAccountingEventInstanceReports = () => {
    const api = useApiService();

    const list = async (payload) => {
        return await api.post("/accountingeventinstancereports/list", payload);
    }

    return { list };
}

export const useAccountingEventSchedules = () => {
    const api = useApiService();

    const getWP = async (accountingEventId) => {
        return await api.get(`/AccountingEventScheduleActions/wp?accountingEventId=${accountingEventId}`);
    }

    const getUnique = async (id) => {
        return await api.get(`/AccountingEventSchedules/${id}`);
    }

    const create = async (ev) => {
        return await api.post(`/AccountingEventSchedules/`, ev);
    }

    const update = async (ev) => {
        return await api.put(`/AccountingEventSchedules/`, ev);
    }

    const execDelete = async (id) => {
        return await api.execDelete(`/AccountingEventSchedules/${id}`);
    }

    return { getWP, getUnique, create, update, execDelete };
}

export const useBankAccounts = () => {
    const api = useApiService();
    
    const get = async () => {
        return await api.get("/bankaccounts");
    };

    const getUnique = async(id) => {
        return await api.get(`/bankaccounts/${id}`);
    }

    const create = async (bankAccount) => {
        return await api.post("/bankaccounts", bankAccount);
    };

    const update = async (bankAccount) => {
        return await api.put("/bankaccounts/", bankAccount);
    }

    return { get, getUnique, create, update };
};

export const useBankTransactionImport = () => {
    const api = useApiService();
    
    const bankTransactionImportParse = async (id, text) => {
        return await api.post("/banktransactionimport/parse", { bankAccountID: id, text: text});
    }

    const bankTransactionImport = async (id, transactions) => {
        return await api.post("/banktransactionimport/import", { bankAccountID: id, bankTransactions: transactions });
    }

    return { bankTransactionImportParse, bankTransactionImport };
}

export const useBankTransactions = () => {
    const api = useApiService();
    
    const get = async() => {
        return await api.get("/banktransactions");
    }

    const getByAccount = async (bankAccountId) => {
        return await api.get("/banktransactionactions/transactions/" + bankAccountId);
    }

    const getUnlinked = async() => {
        return await api.get("/banktransactionactions/unlinked");
    }

    const execDelete = async(id) => {
        return await api.execDelete(`/banktransactions/${id}`);
    }

    return { get, getByAccount, getUnlinked, execDelete };
}

export const useCounterparts = () => {
    const api = useApiService();

    const get = async () => {
        return await api.get("/counterparts");
    }

    const getUnique = async (id) => {
        return await api.get(`/counterparts/${id}`);
    }

    const create = async (ev) => {
        return await api.post(`/counterparts/`, ev);
    }

    const update = async (ev) => {
        return await api.put(`/counterparts/`, ev);
    }

    const execDelete = async (id) => {
        return await api.execDelete(`/counterparts/${id}`);
    }

    return { get, getUnique, create, update, execDelete };
}

export const useDocuments = () => {
    const api = useApiService();
    
    const getWithTags = async () => {
        return await api.get("/documentactions/getwithtags");
    }

    const uploadFiles = async (type, files, tags) => {
        const formData = new FormData();

        files.forEach((file, index) => {
            formData.append(`Files[${index}].FormFile`, file.formFile);
            formData.append(`Files[${index}].Description`, file.description);
            formData.append(`Files[${index}].LastModified`, file.lastModified);
        });

        formData.append('DocumentType', type.toString());
        
        tags.forEach((tag, index) => {
            formData.append(`Tags[${index}]`, tags[index]);
        });

        const response = await api.postRaw('/documentactions/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response && response.status == 200;
    }

    const getDocumentWithUrl = async (id) => {
        return await api.get(`/documentactions/getDocumentWithUrl/${id}`);
    }

    const attachVouchers = async (documentList, instanceList) => {
        return await api.post('/documentactions/attachVouchers', {
            documentIDList: documentList,
            instanceList: instanceList
        });
    }

    return { getWithTags, uploadFiles, getDocumentWithUrl, attachVouchers };
}

export const useSections = () => {
    const api = useApiService();

    const get = async () => {
        return await api.get("/sections");
    }

    const getUnique = async (id) => {
        return await api.get(`/sections/${id}`);
    }

    const create = async (ev) => {
        return await api.post(`/sections/`, ev);
    }

    const update = async (ev) => {
        return await api.put(`/sections/`, ev);
    }

    const execDelete = async (id) => {
        return await api.execDelete(`/sections/${id}`);
    }

    const createMultiple = async(params) => {
        return await api.post('/sectionactions/multiple', params);
    }

    const fetchInfo = async() => {
        return await api.get('/sectionactions/fetchinfo');
    }

    return { get, getUnique, create, update, execDelete, createMultiple, fetchInfo };
}

export const useSectionRents = () => {
    const api = useApiService();

    const get = async () => {
        return await api.get("/sectionrents");
    }

    const getWP = async (sectionId) => {
        return await api.get(`/sectionrentactions/wp?sectionId=${sectionId}`);
    }

    const getAppliedRents = async (rentDate) => {
        let encodedDate = "";
        if (rentDate) {
            const formattedDate = rentDate.format('YYYY-MM-DD');
            encodedDate = encodeURIComponent(formattedDate);
        }

        return await api.get(`/sectionrentactions/appliedRents?rentDate=${encodedDate}`);
    }

    const getUnique = async (id) => {
        return await api.get(`/sectionrents/${id}`);
    }

    const create = async (ev) => {
        return await api.post(`/sectionrents/`, ev);
    }

    const update = async (ev) => {
        return await api.put(`/sectionrents/`, ev);
    }

    const registerMultiple = async (payload) => {
        return await api.post('/sectionrentactions/registermultiple', payload);
    }

    const execDelete = async (id) => {
        return await api.execDelete(`/sectionrents/${id}`);
    }

    return { get, getWP, getAppliedRents, getUnique, create, update, registerMultiple, execDelete };
}