import React from 'react';
import { useUser } from '../UserContext';

const UserHello = () => {
    const {user, setUser} = useUser();

    return (
        <section>
            {user && <p>Du er pålogget med brukernavn: <em>{user.username}</em></p>}
            {user && <p>Du har følgende roller: <em>{user.roles.join(", ")}</em></p>}
        </section>
    );
};

export default UserHello;