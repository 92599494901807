import { jwtDecode } from 'jwt-decode';

export function isTokenExpiringSoon(token) {
    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        const thresholdSeconds = 10 * 60;
        
        const remaining = decoded.exp - currentTime;

        console.log('Token remaining: ');
        console.log(remaining);
        console.log(remaining / 60);

        return remaining < thresholdSeconds;
    } catch (error) {
        console.error("Error decoding token");

        return true;
    }
}