import logo from './logo.svg';
import './App.css';
import { UserProvider } from './UserContext';
import SamportApp from './Components/SamportApp';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { nbNO } from '@mui/material/locale';
import { nbNO as DGnbNO } from '@mui/x-data-grid';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/nb';

const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
},
  nbNO,
  DGnbNO
);

export default function App() {
  return (
    <UserProvider>
      <ThemeProvider theme={darkTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb">
          <CssBaseline />
            <Router>
              <SamportApp />
            </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </UserProvider>
  );
}