import React, { useState } from 'react';
import { useUser } from '../../UserContext';
import { Container, Box, Typography, TextField, Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [credentials, setCredentials] = useState({ username: '', password: '', clientId: '' });
    const [msg, setMsg] = useState(null);
    const [isAuthenticating, setIsAuthenticating] = useState(false);

    const { login } = useUser(); 
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsAuthenticating(true);
        let success = await login(credentials);

        if (success) {
            navigate('/');
        } else {
            setMsg('Error occured');
        }

        setIsAuthenticating(false);
    };

    const handleChange = (event) => {
        setCredentials({ ...credentials, [event.target.name]: event.target.value });
    };

    const renderMessage = () => {
        if (isAuthenticating) {
            return (
                <progress />
            );
        }
        if (!msg || msg.length == 0) {
            return;
        }

        return (
            <Typography variant="p" sx={{ color: 'red' }}>
                {msg}
            </Typography>
        )
    }

    return (
        <Container maxWidth="xs">
            <Box 
                sx={{ 
                    marginTop: 8, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center' 
                }}
            >
                <Paper sx={{ padding: 2, marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    {renderMessage()}
                    <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="username"
                            autoComplete="email"
                            autoFocus
                            value={credentials.username}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={credentials.password}
                            onChange={handleChange}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            disabled={isAuthenticating}
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
}

export default Login;
