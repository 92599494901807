import React, { useState } from 'react';

import { Card, CardContent, Stack, TextField, IconButton, Button, Paper, FormControlLabel, Checkbox, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const defaultFilter = () => {
    return {
        documentType: 1,
        tags: [],
        hideLinked: false,
    };
}

const DocumentsViewerFilter = ({ givenFilters = defaultFilter(), onFilterChanged }) => {
    const [filter, setFilter] = useState(givenFilters);
    const [tag, setTag] = useState('');

    const applyFilter = () => {
        onFilterChanged(filter);
    }

    const deleteTag = (t) => {
        const n_tags = filter.tags.filter(e => e !== t);

        setFilter({ ...filter, tags: n_tags });
    }

    const addTag = () => {
        if (tag && !filter.tags.includes(tag)) { 
            setFilter(prevFilter => ({
                ...prevFilter,
                tags: [...prevFilter.tags, tag]
            }));
            setTag('');
        }
    }

    return (
        <Card>
            <CardContent>
                <Stack spacing={2}>
                    <Stack direction="row" spacing={2}>
                        <FormControlLabel control={<Checkbox checked={filter.hideLinked} onChange={(e) => setFilter({...filter, hideLinked: e.target.checked})} />} label="Hide linked" />
                        <TextField
                            size='small'
                            label="Tag"
                            value={tag}
                            onChange={(ev) => setTag(ev.target.value)}
                        />
                        <Button size='small' onClick={addTag}>Add</Button>
                        {filter.tags.map(t => (
                            <Paper key={t}><Typography>{t}</Typography> <IconButton size='small' onClick={() => deleteTag(t)}><DeleteIcon fontSize="small" /></IconButton></Paper>
                        ))}
                        <Button size='small' type='contained' onClick={applyFilter} fullWidth>Apply</Button>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    );
}

export default DocumentsViewerFilter;