import React, { useState, useEffect } from 'react';
import { Box, Paper, Stack, Divider, TextField, Button } from '@mui/material';
import { useBankTransactions } from '../Shared/ApiServiceHook';
import { formatCurrency, formatDate, formatDecimal } from '../../services/formatUtils';
import { DataGrid, nbNO as dataGridNbNO } from '@mui/x-data-grid';
import BankTransactionsTableFilter from './BankTransactionsTableFilter';

const BankTransactionsTable = ({ onBankTransactionsSelected, ts }) => {
    const [bankTransactions, setBankTransactions] = useState([]);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [filter, setFilter] = useState({ searchText: '' });

    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    const api = useBankTransactions();

    useEffect(() => {
        fetch();
    }, [ts]);
    
    useEffect(() => {
        filterTransactions();
    }, [bankTransactions, filter]);

    useEffect(() => {
        onBankTransactionsSelected(rowSelectionModel);
    }, [rowSelectionModel.length]);

    const fetch = async () => {
        console.log('fetching');
        const bts = await api.getUnlinked();
        setBankTransactions(bts);
    }

    const filterTransactions = () => {
        console.log('filtering');
        const ft = [];

        bankTransactions.map(t => {
            if (match(t)) {
                ft.push(t);
            }
        });

        setFilteredTransactions(ft);
    }

    const match = (t) => {
        if(!filter || !filter.searchText || filter.searchText == '') return true;

        return t.description.toLowerCase().includes(filter.searchText.toLowerCase());
    }

    const cols = [
        { field: 'bankTransactionID' },
        { field: 'bookDate', headerName: 'Date', width: '50px', flex: 1 , valueFormatter: (params) => {
            return formatDate(params.value);
        }},
        { field: 'amount', headerName: 'Amount', width: '50px', flex: 1, type: 'number', valueFormatter: (params) => {
            return formatCurrency(params.value);
        }},
        { field: 'description', headerName: 'Desc', width: '100px', flex: 3},
        { field: 'reference', headerName: 'Ref', width: '100px', flex: 2},
    ];

    return (
        <Box>
            <Paper elevation={3} sx={{ p: 2 }}>
                <BankTransactionsTableFilter onApplyFilter={(f) => setFilter(f)} />
                <Divider />
                <div>
                    <DataGrid
                        rowHeight={25}
                        rows={filteredTransactions}
                        columns={ cols }
                        getRowId={(r) => r.bankTransactionID}
                        columnVisibilityModel= {{
                            bankTransactionID: false 
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 100 },
                            },
                        }}
                        pageSizeOptions={[50, 100]}
                        checkboxSelection
                        localeText={dataGridNbNO}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionModel}
                    />
                </div>
            </Paper>
        </Box>
    )
}

export default BankTransactionsTable;