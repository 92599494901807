import React, { useState, useEffect } from 'react';
import { Button, FormControl, Grid, InputLabel, Select, MenuItem } from '@mui/material';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs, { Dayjs } from 'dayjs';

import { useAccountingEventSchedules } from '../Shared/ApiServiceHook';

const AccountingEventSchedulesForm = ({ id, accountingEventId, onClose }) => {
    const [entity, setEntity] = useState({ accountingEventId: accountingEventId, startDate: dayjs("2023-01-01"), frequency: 1 });

    const api = useAccountingEventSchedules();

    useEffect(() => {
        const fetch = async () => {
            if (id) {
                const ent = await api.getUnique(id);
                setEntity(ent);
            }
        };

        fetch();
    }, [id]);

    const handleCreateOrUpdate = async (ev) => {
        ev.preventDefault();

        if (id) {
            await api.update(entity);
        } else {
            await api.create(entity);
        }

        onClose();
    };

    return (
        <React.Fragment>
            <form onSubmit={(event) => handleCreateOrUpdate(event)} className="samport-form">
                <FormControl>
                    <DateField
                        label="StartDate"
                        format="DD-MM-YYYY"
                        value={entity.startDate}
                        onChange={(e) => setEntity({ ...entity, startDate: e })}
                    />
                </FormControl>
                <FormControl>
                    <Grid item xs={12}>                    
                        <InputLabel id="frequency-label">Frequency</InputLabel>
                        <Select
                            fullWidth
                            labelId="frequency-label"
                            id="frequency"
                            value={entity.frequency}
                            label="Frequency"
                            onChange={(e) => setEntity({ ...entity, frequency: e.target.value})}>
                                <MenuItem key={1} value={1}>Yearly</MenuItem>
                                <MenuItem key={12} value={12}>Monthly</MenuItem>
                        </Select>
                    </Grid>
                </FormControl>

                <Button type="submit">{id ? 'Update' : 'Create'}</Button>
            </form>
        </React.Fragment>
    );
};

export default AccountingEventSchedulesForm;
