import React, { useState, useEffect } from 'react';
import { useSectionRents, useSections } from '../Shared/ApiServiceHook';

import { Paper, FormControl, TextField, Button, Stack } from '@mui/material';
import { DateField } from '@mui/x-date-pickers';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs, { Dayjs } from 'dayjs';

const SectionRentMultiple = () => {
    const [sectionRents, setSectionRents] = useState([]);
    const [startDate, setStartDate] = useState(dayjs());
    const [startDateLocked, setStartDateLocked] = useState(true);

    const api = useSectionRents();
    const sectionsApi = useSections();

    useEffect(() => {
        fetch();
    }, [startDateLocked]);

    const fetch = async () => {
        if (startDate.isValid() && startDateLocked) {
            const sl = await sectionsApi.get();
            const ar = await api.getAppliedRents(startDate);

            const sr = [];

            sl.map(s => {
                const appliedRent = ar.find(r => r.sectionID === s.sectionID);
                sr.push({
                    sectionID: s.sectionID,
                    sectionNumber: s.sectionNumber,
                    rent: appliedRent ? appliedRent.rent : 0,
                });
            });

            setSectionRents(sr);
        }
    }

    const pad = (n) => {
        if (n < 10) {
            return "0" + n;
        }

        return n;
    }

    const handleSave = async () => {
        const payload = {
            startDate: startDate,
            items: sectionRents
        };

        await api.registerMultiple(payload);
        await fetch();
    }

    const updateRent = (sectionID, newRent) => {
        const updatedSectionRents = sectionRents.map(sr => {
            if (sr.sectionID === sectionID) {
                return { ...sr, rent: parseFloat(newRent) || 0 };
            }

            return sr;
        });

        setSectionRents(updatedSectionRents);
    }

    return (
        <React.Fragment>
            <Paper sx={{ p: 2 }}>
                <Stack direction="row" spacing={2}>
                <FormControl>
                    <DateField
                        disabled={startDateLocked}
                        label="Rent Date"
                        format="DD-MM-YYYY"
                        value={startDate}
                        onChange={(e) => setStartDate(e)}
                    />
                </FormControl>
                <Button onClick={() => setStartDateLocked(!startDateLocked)}>{startDateLocked ? 'Unlock' : 'Lock and fetch'}</Button>
                </Stack>
                
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    SectionNumber
                                </TableCell>
                                <TableCell>
                                    Rent
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sectionRents.map(sr => (
                                <TableRow key={sr.sectionID}>
                                    <TableCell size="small">{pad(sr.sectionNumber)}</TableCell>
                                    <TableCell size="small">
                                        <TextField
                                            type="number"
                                            value={sr.rent}
                                            onChange={(e) => updateRent(sr.sectionID, e.target.value)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Paper>{sectionRents.reduce((ps, s) => ps + s.rent, 0)}</Paper>
                <Button onClick={handleSave} fullWidth>Save</Button>
            </Paper>
        </React.Fragment>
    )
}

export default SectionRentMultiple;