import React, { useState, useEffect } from 'react';
import { useDocuments } from '../Shared/ApiServiceHook';
import DocumentPreview from './DocumentPreview';
import { Grid, IconButton, Stack, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { DataGrid, nbNO as dataGridNbNO } from '@mui/x-data-grid';
import { formatDate, formatSize } from '../../services/formatUtils';
import DocumentsViewerFilter from './DocumentsViewerFilter';

const getDefaultFilters = () => {
    return { documentType: 1, tags: [], hideLinked: false };
}
const DocumentsViewer = ({ key, givenFilters = getDefaultFilters(), documentIds = null, hideFilters = false, onSelectionChanged = (sel) => {}, onFiltersChanged = (f) => {}}) => {
    const [documents, setDocuments] = useState([]);
    const [allDocuments, setAllDocuments] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [previewDoc, setPreviewDoc] = useState(0);
    const [previewUrl, setPreviewUrl] = useState('');

    const [filter, setFilter] = useState(givenFilters);

    useEffect(() => {
        setFiltered();
    }, [filter, allDocuments]);

    useEffect(() => {
        console.log('documents fetching');
        fetch();
    }, [key]);

    useEffect(() => {
        onSelectionChanged(selectedRows);
    }, [selectedRows]);

    const docsApi = useDocuments();

    const setFiltered = () => {
        const docs = allDocuments.filter(d => {
            if (d.documentType !== filter.documentType) {
                return false;
            }

            if (filter.hideLinked && 
                (d.documentLinks.length > 0 || d.hide)) {
                return false;
            }

            if (filter.tags.length == 0) {
                return true;
            }

            const hasMatchingTag = filter.tags.some(t => {
                const foundTag = d.documentTags.find(e => e.tag.toLowerCase() === t.toLowerCase());
                return foundTag !== undefined;
            });

            if (hasMatchingTag) {
                return true;
            }

            return false;
        });

        setDocuments(docs);
    }

    const fetch = async () => {
        const f_documents_all = await docsApi.getWithTags();

        let f_documents = f_documents_all;

        if (f_documents && documentIds) {
            f_documents = f_documents.filter(d => documentIds.includes(d.documentID));
        }

        setAllDocuments(f_documents ?? []);
    }

    const previewDocument = (e, row) => {
        e.stopPropagation();
        setPreviewDoc(row.documentID);
        setSelectedRows([row.documentID]);
    }

    const openDocument = async (row) => {
        const url_response = await docsApi.getDocumentWithUrl(row.documentID);

        window.open(url_response.url, '_blank');
    }

    const cols = [
        { field: 'documentID' },
        // { field: 'lastModifiedDate', headerName: 'Last modified', width: '50px', flex: 1 , valueFormatter: (params) => {
        //     return params ? formatDate(params.value) : '';
        // }},
        // { field: 'uploadedTimestamp', headerName: 'Uploaded', width: '50px', flex: 1 , valueFormatter: (params) => {
        //     return params ? formatDate(params.value) : '';
        // }},
        {
            field: 'documentTags', headerName: 'Tags', width: '150px', flex: 3, valueFormatter: (params) => {
                return params ? params.value.map(t => t.tag).join(", ") : '';
            }
        },
        { field: 'fileName', headerName: 'OriginalName', width: '100px', flex: 3 },
        { field: 'contentType', headerName: 'Type', width: '100px', flex: 2 },
        { field: 'size', headerName: 'Size', width: '50px', flex: 1, valueFormatter: (params) => formatSize(params.value) },
        {
            field: 'actions2',
            headerName: '',
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <Stack direction='row'>
                    <IconButton onClick={(e) => previewDocument(e, params.row)} aria-label="view">
                        <VisibilityIcon fontSize="small" />
                    </IconButton>
                </Stack>
            ),
        },
    ];

    const handleFilterChange = (newFilter) => {
        onFiltersChanged(newFilter);
        setFilter(newFilter);
    }

    return (
        <React.Fragment>
            <Stack spacing={2}>
                { !hideFilters && (
                    <DocumentsViewerFilter givenFilters={filter} onFilterChanged={handleFilterChange} />
                )}
                <DataGrid
                    rowHeight={25}
                    rows={documents}
                    columns={cols}
                    getRowId={(r) => r.documentID}
                    columnVisibilityModel={{
                        documentID: false
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        },
                    }}
                    pageSizeOptions={[20, 50, 100]}
                    checkboxSelection
                    localeText={dataGridNbNO}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setSelectedRows(newRowSelectionModel);
                    }}
                    rowSelectionModel={selectedRows}
                />
                {previewDoc !== 0 && (
                    <DocumentPreview documentID={previewDoc} onUrlSet={(url) => setPreviewUrl(url)} />
                )}

            </Stack>

        </React.Fragment>
    )
}

export default DocumentsViewer;