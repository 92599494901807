import React, { useState } from 'react';
import AccountingEventInstanceReport from '../AccountingEventInstances/AccountingEventInstanceReport';
import DocumentsViewer from '../Documents/DocumentsViewer';
import { uuidv4 } from '../../services/samportUtils';
import { Grid, Stack, Button, Typography, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { useDocuments } from '../Shared/ApiServiceHook';

const RegisterAttachments = () => {
    const [uuid, setUuid] = useState(uuidv4());
    const [isLoading, setIsLoading] = useState(false);

    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [selectedInstances, setSelectedInstances] = useState([]);

    const [documentFilters, setDocumentFilters] = useState({ documentType: 1, tags: [], hideLinked: true });

    const [instanceFilters, setInstanceFilters] = useState({ hideAttached: true, counterpartSearch: '', typeSearch: '' });

    const docsApi = useDocuments();

    const attach = async () => {
        setIsLoading(true);
        await docsApi.attachVouchers(selectedDocuments, selectedInstances);
        setUuid(uuidv4());
        setIsLoading(false);
    }

    return (
        <React.Fragment>
            <Stack spacing={2}>
                {isLoading && <progress />}
                <Typography>Selected instances: {selectedInstances.length}, Selected documents: {selectedDocuments.length}</Typography>
                <Button variant="contained" fullWidth onClick={attach} disabled={isLoading}>Attach</Button>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Stack spacing={2} direction="row">
                        <FormControlLabel control={<Checkbox checked={instanceFilters.hideAttached} onChange={(e) => setInstanceFilters({...instanceFilters, hideAttached: e.target.checked})} />} label="Hide linked" />
                        <TextField
                            label="Counterpart"
                            value={instanceFilters.counterpartSearch}
                            size="small"
                            onChange={(ev) => setInstanceFilters({ ...instanceFilters, counterpartSearch: ev.target.value })} />
                        <TextField
                            label="Type"
                            value={instanceFilters.typeSearch}
                            size="small"
                            onChange={(ev) => setInstanceFilters({ ...instanceFilters, typeSearch: ev.target.value })} />
                        <Button onClick={() => setUuid(uuidv4())} variant='contained'>Apply</Button>
                        </Stack>
                        <AccountingEventInstanceReport key={uuid} onSelectionChanged={(sel) => setSelectedInstances(sel)} givenFilters={instanceFilters} />
                    </Grid>
                    <Grid item xs={6}>
                        <DocumentsViewer key={uuid} onSelectionChanged={(sel) => setSelectedDocuments(sel)} givenFilters={documentFilters} onFiltersChanged={(f) => setDocumentFilters(f)} />
                    </Grid>
                </Grid>
            </Stack>
        </React.Fragment>
    )
}

export default RegisterAttachments;