import React, { useState, useEffect } from 'react';
import { useSectionRents } from '../Shared/ApiServiceHook';

import { Paper, IconButton, Grid, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as dayjs from 'dayjs';

import GenericModal from '../Shared/GenericModal';
import SectionRentsForm from './SectionRentsForm';

const SectionRent = ({ sectionID }) => {
    const [openModal, setOpenModal] = useState(false);
    const [currentId, setCurrentId] = useState(null);

    const [sectionRents, setSectionRents] = useState([]);

    const api = useSectionRents();

    useEffect(() => {
        fetch();
    }, []);

    const fetch = async () => {
        const rents = await api.getWP(sectionID);

        console.log(rents);
        setSectionRents(rents);
    };

    const handleClose = () => {
        setOpenModal(false);
        fetch();
    };

    const handleOpen = (sr) => {
        setCurrentId(sr?.sectionRentID);
        setOpenModal(true);
    }

    const deleteItem = async (id) => {
        await api.execDelete(id);

        fetch();
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={3}><Grid container justifyContent="flex-end"><Button onClick={() => handleOpen(null)} >Create</Button></Grid></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>StartDate</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        sectionRents.map(sr => (
                            <TableRow onDoubleClick={() => handleOpen({ sr })} key={sr.sectionRentID}>
                                <TableCell>{dayjs(sr.startDate).format('YYYY-MM-DD')}</TableCell>
                                <TableCell>{sr.rent}</TableCell>
                                <TableCell><IconButton onClick={() => deleteItem(sr.sectionRentID)}><DeleteIcon /></IconButton></TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>

            <GenericModal open={openModal} handleClose={handleClose} title={'Add or edit'}>
                <SectionRentsForm id={currentId} sectionID={sectionID} onClose={handleClose} />
            </GenericModal>
        </TableContainer>
    );
};

export default SectionRent;