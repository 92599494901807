import React, { useState, useEffect } from 'react';

import { useAccounts } from '../../Shared/ApiServiceHook';

import { Paper, IconButton, Grid, Button, TextField, InputAdornment, FormControl, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const PostingsEditTable = ({ postingsUpdated }) => {
    const [accounts, setAccounts] = useState([]);
    const [postings, setPostings] = useState([]);
    const [editPosting, setEditPosting] = useState({});

    const handleEditPosting = (p) => {
        setEditPosting(p);
    }
    const accountsApi = useAccounts();
    // const postingsApi = usePostings();

    // useEffect(() => {
    //     fetchPostings();
    // }, [accountingEventId]);

    useEffect(() => {
        postingsUpdated(postings);
    }, [postings]);

    useEffect(() => {
        fetch();
    }, []);

    const fetchPostings = async () => {
        // const f_postings = await postingsApi.getWP(accountingEventId);

        // setPostings(f_postings);
    };

    const fetch = async () => {
        const f_accounts = await accountsApi.get();

        setAccounts(f_accounts);
    }

    const deleteItem = async (recordID) => {
        const f_postings = postings.filter(p => p.recordID !== recordID);

        setPostings(f_postings);
    }

    const handleSavePosting = (p) => {
        const f_postings = postings.filter(p => p.recordID !== editPosting.recordID);
        f_postings.push(editPosting);

        setEditPosting({});
        setPostings(f_postings);
    }

    const renderEditRow = (p) => {
        return (
            <TableRow key={p.recordID}>
                <TableCell>{p.recordID}</TableCell>
                <TableCell>
                    <TextField
                        fullWidth
                        type="Number"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">NOK</InputAdornment>,
                        }}
                        value={editPosting.amount}
                        onChange={(e) => setEditPosting({ ...editPosting, amount: e.target.value })}
                    />
                </TableCell>
                <TableCell>
                    <FormControl>
                        {/* <InputLabel id="ae-type-label">Debit</InputLabel> */}
                        <Select
                            labelId="ae-type-label"
                            id="ae-type"
                            value={editPosting.debit_AccountID}
                            label="Debit Account"
                            onChange={(e) => setEditPosting({ ...editPosting, debit_AccountID: e.target.value })}>
                            {accounts.map(a => (
                                <MenuItem key={a.accountID} value={a.accountID}>{a.accountNumber} - {a.description}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell>
                    <FormControl>
                        {/* <InputLabel id="ae-type-label">Credit</InputLabel> */}
                        <Select
                            labelId="ae-type-label"
                            id="ae-type"
                            value={editPosting.credit_AccountID}
                            label="Credit Account"
                            onChange={(e) => setEditPosting({ ...editPosting, credit_AccountID: e.target.value })}>
                            {accounts.map(a => (
                                <MenuItem key={a.accountID} value={a.accountID}>{a.accountNumber} - {a.description}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </TableCell>
                <TableCell>
                    <FormControl>
                        <TextField
                            label="Description"
                            fullWidth
                            value={editPosting.description}
                            onChange={(e) => setEditPosting({ ...editPosting, description: e.target.value })}
                        />
                    </FormControl>
                </TableCell>
                <TableCell>
                    <FormControl>
                        <TextField
                            label="Comment"
                            fullWidth
                            value={editPosting.comment}
                            onChange={(e) => setEditPosting({ ...editPosting, comment: e.target.value })}
                        />
                    </FormControl>
                </TableCell>
                <TableCell><IconButton onClick={() => handleSavePosting(p)}><SaveIcon /></IconButton></TableCell>
            </TableRow>
        );
    }

    const renderViewRow = (p) => {
        return (
        <TableRow key={p.recordID}>
                <TableCell>{p.recordID}</TableCell>
                <TableCell>
                    {p.amount}
                </TableCell>
                <TableCell>
                    {renderAccount(p.debit_AccountID)}
                </TableCell>
                <TableCell>
                    {renderAccount(p.credit_AccountID)}
                </TableCell>
                <TableCell>
                    {p.description}
                </TableCell>
                <TableCell>
                   {p.comment}
                </TableCell>
                <TableCell>
                    <IconButton onClick={() => deleteItem(p.recordID)}><DeleteIcon /></IconButton>
                    <IconButton onClick={() => handleEditPosting(p)}><EditIcon /></IconButton>
                </TableCell>
            </TableRow>
        );
    }

    const renderAccount = (accountId) => {
        let acc = accounts.find(a => a.accountID === accountId);

        if (acc) {
            return (
                <React.Fragment>{acc.accountNumber} - {acc.description}</React.Fragment>
            );
        }
        else {
            return (<React.Fragment></React.Fragment>);
        }
    }

    const renderRow = (posting) => {
        console.log('rendering row recordId: ' + posting.recordID);

        if (posting.recordID === editPosting.recordID) {
            return renderEditRow(posting);
        }

        return renderViewRow(posting);
    }

    const addRow = () => {
        console.log('adding row');
        console.log('postings: ');
        console.log(postings);
        let next = 0;

        postings.forEach(p => {
            if (p.recordID > next) {
                next = p.recordID
            }
        });

        next = next + 1;

        let p = {
            recordID: next,
            debit_AccountID: null,
            credit_AccountID: null,
            description: '',
            comment: '',
        };

        setPostings([...postings, p]);
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={7}><Grid container justifyContent="flex-end"><Button onClick={() => addRow()} >Add</Button></Grid></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>RecordID</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>DebitAccount</TableCell>
                        <TableCell>CreditAccount</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Comment</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        postings.map(p => 
                            renderRow(p)
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PostingsEditTable;
