import React, { useState, useEffect } from 'react';

import { useAccountingEvents, useCounterparts } from './ApiServiceHook';
import { Stack, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs, { Dayjs } from 'dayjs';

const EventSelector = ({ onParamsChanged }) => {
    const [filter, setFilter] = useState({ type: 0, text: '', accEvID: 0, mode: 1, occuredDate: dayjs(), counterpartID: 0 });
    const [accEvs, setAccEvs] = useState([]);
    const [filteredAccEvs, setFilteredAccEvs] = useState([]);
    const [counterparts, setCounterparts] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const api = useAccountingEvents();
    const counterpartsApi = useCounterparts();

    useEffect(() => {
        const fetch = async () => {
            const f = await api.get();

            const f_sorted = f.sort((a, b) => a.description.localeCompare(b.description));
            setAccEvs(f_sorted);

            const cp = await counterpartsApi.get();
            const cp_sorted = cp.sort((a, b) => a.counterpartName.localeCompare(b.counterpartName));
            setCounterparts(cp_sorted);
        }

        fetch();
        setIsLoading(false);
    }, []);

    useEffect(() => {
        filterAccEvs();
    }, [accEvs, filter.type]);

    const filterAccEvs = () => {
        const filtered = [];

        accEvs.map(f => {
            if (!filter.type || filter.type == 0) {
                filtered.push(f);
            } else if (filter.type === f.accountingEventType) {
                filtered.push(f);
            }
        });

        setFilteredAccEvs(filtered);
    }

    useEffect(() => {
        onParamsChanged(filter);
    }, [filter.accEvID, filter.mode, filter.occuredDate, filter.counterpartID]);

    if (isLoading) {
        return "Loading...";
    }

    return (
        <React.Fragment>
            <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                <FormControl>
                    <InputLabel id="ae-type-label">Type</InputLabel>
                    <Select
                        labelId="ae-type-label"
                        id="ae-type"
                        label="Type"
                        defaultValue={0}
                        displayEmpty
                        value={filter.type}
                        onChange={(e) => setFilter({ ...filter, type: e.target.value })}>
                        <MenuItem key={0} value={0}><em>Filter type</em></MenuItem>
                        <MenuItem key={1} value={1}>Cost</MenuItem>
                        <MenuItem key={2} value={2}>Income</MenuItem>
                        <MenuItem key={3} value={3}>Custom</MenuItem>
                        <MenuItem key={4} value={4}>Rent Payment</MenuItem>
                        <MenuItem key={5} value={5}>Rent Accrued</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="sel-ae-label">Event</InputLabel>
                    <Select
                        labelId="sel-ae-label"
                        id="sel-ae-type"
                        value={filter.accEvID}
                        displayEmpty
                        renderValue={(selected) => {
                            if (selected === '' || selected === 0) {
                                return <em>Select event</em>;
                            }

                            const selectedEvent = filteredAccEvs.find(event => event.accountingEventID === selected);
                            return selectedEvent ? selectedEvent.description : <em>Select event</em>;
                        }}
                        label="Result Account"
                        onChange={(e) => setFilter({ ...filter, accEvID: e.target.value })}>
                        {filteredAccEvs.map(a => (
                            <MenuItem key={a.accountingEventID} value={a.accountingEventID}>{a.description}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="ae-mode-label">Mode</InputLabel>
                    <Select
                        labelId="ae-mode-label"
                        id="ae-mode"
                        label="Mode"
                        defaultValue={1}
                        displayEmpty
                        value={filter.mode}
                        onChange={(e) => setFilter({ ...filter, mode: e.target.value })}>
                        <MenuItem key={1} value={1}>Direct</MenuItem>
                        <MenuItem key={3} value={3}>Payment</MenuItem>
                        <MenuItem key={4} value={4}>Periodized</MenuItem>
                    </Select>
                </FormControl>
                {filter.mode === 4 && (
                    <FormControl>
                        <DateField
                            label="Occured date"
                            format="DD-MM-YYYY"
                            value={filter.occuredDate}
                            onChange={(e) => setFilter({ ...filter, occuredDate: e })}
                        />
                    </FormControl>
                )}
                <FormControl>
                    <InputLabel id="ae-cp-label">Counterpart</InputLabel>
                    <Select
                        labelId="ae-cp-label"
                        id="ae-cp"
                        label="Counterpart"
                        defaultValue={0}
                        displayEmpty
                        value={filter.counterpartID}
                        onChange={(e) => setFilter({ ...filter, counterpartID: e.target.value })}>
                        <MenuItem key={0} value={0}>Fetch default</MenuItem>
                        {counterparts.map(c => (
                            <MenuItem key={c.counterpartID} value={c.counterpartID}>{c.counterpartName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
        </React.Fragment>
    );
}

export default EventSelector;