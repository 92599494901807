import React from 'react';
import { Modal, Box, Typography } from '@mui/material';

const GenericModal = ({ open, handleClose, title, children, width="400px", height="400px" }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        height: height,
        maxHeight: "90vh",
        maxWidth: "90vw",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto',
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-title" variant="h6" component="h2">
                    {title}
                </Typography>
                {children}
            </Box>
        </Modal>
    );
};

export default GenericModal;
