import React, { useEffect, useState } from 'react';

import { useAccounts } from '../Shared/ApiServiceHook';

import { Stack, IconButton, Divider } from '@mui/material';

import { DataGrid, nbNO as dataGridNbNO } from '@mui/x-data-grid';

import AccountsForm from './AccountsForm';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';

const AccountsOverview = () => {
    const [entities, setEntities] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [editId, setEditId] = useState(0);

    const api = useAccounts();

    useEffect(() => {
        fetch();
    }, []);

    const fetch = async () => {
        const f = await api.get();

        setEntities(f);
    }

    const editAccount = (e, row) => {
        e.stopPropagation();
        setSelectedRows([row.accountID]);
        setEditId(row.accountID);
    }

    const onSubmit = () => {
        fetch();
        setEditId(0);
        setSelectedRows([]);
    }

    const cols = [
        { field: 'accountID' },
        { field: 'groupAccountNumber', headerName: 'GroupNumber', width: '100px', flex: 2 },
        { field: 'accountNumber', headerName: 'Number', width: '100px', flex: 2 },
        { field: 'description', headerName: 'Description', width: '100px', flex: 3 },
        { field: 'isRF1140Balance', headerName: 'RF1140 Balance', width: '100px', flex: 1, renderCell: (params) => {
            return params && params.value ? (<CheckIcon />) : '';
        } },
        { field: 'isRF1140Result', headerName: 'RF1140 Result', width: '100px', flex: 1, renderCell: (params) => {
            return params && params.value ? (<CheckIcon />) : '';
        } },
        {
            field: 'actions2',
            headerName: '',
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <Stack direction='row'>
                    <IconButton onClick={(e) => editAccount(e, params.row)} aria-label="view">
                        <EditIcon fontSize="small" />
                    </IconButton>
                </Stack>
            ),
        },
    ];

    return (
        <React.Fragment>
            <Stack spacing={2}>
                <AccountsForm id={editId} onClose={onSubmit} />
                <Divider />
                <DataGrid
                    rowHeight={25}
                    rows={entities}
                    columns={cols}
                    getRowId={(r) => r.accountID}
                    columnVisibilityModel={{
                        accountID: false
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 50 },
                        },
                    }}
                    pageSizeOptions={[20, 50, 100]}
                    checkboxSelection
                    localeText={dataGridNbNO}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setSelectedRows(newRowSelectionModel);
                    }}
                    rowSelectionModel={selectedRows}
                />
            </Stack>
        </React.Fragment>
    )
}

export default AccountsOverview;