import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSections } from '../Shared/ApiServiceHook';
import { Paper, Stack, TextField, Button, Divider, Typography } from '@mui/material';
import SectionRent from './SectionRent';

const SectionEdit = () => {
    const { id } = useParams();
    
    const api = useSections();
    const [section, setSection] = useState({ sectionID: 0, sectionNumber: 0, floor: 0, sectionDistributionShare: 0});

    useEffect(() => {
        const fetch = async () => {
            const s = await api.getUnique(id);
            setSection(s);
        }

        fetch();
    }, []);

    const saveChanges = async () => {
        await api.update(section);
    }

    return (
        <React.Fragment>
            <Stack direction="column" spacing={2}>
                <Paper sx={{p: 2}} elevation={3}>
                    <Stack direction="column" spacing={2}>
                        <Divider sx={{ mb: 4 }}><Typography variant="h4">Section Details</Typography></Divider>
                        <TextField 
                            label="Section Number"
                            type="number"
                            value={section.sectionNumber}
                            onChange={(e) => setSection({...section, sectionNumber: e.target.value})}
                        />
                        <TextField 
                            label="Floor"
                            type="number"
                            value={section.floor}
                            onChange={(e) => setSection({...section, floor: e.target.value})}
                        />
                        <TextField 
                            label="Distribution share"
                            type="number"
                            value={section.sectionDistributionShare}
                            onChange={(e) => setSection({...section, sectionDistributionShare: e.target.value})}
                        />
                        <Button onClick={saveChanges}>Save</Button>
                    </Stack>
                </Paper>
                <Paper sx={{p:2}} elevation={3}>
                    <Stack direction="column" spacing={2}>
                        <Divider sx={{ mb: 4 }}><Typography variant="h4">Section Rent</Typography></Divider>
                        <SectionRent sectionID={id} />
                    </Stack>
                </Paper>
            </Stack>
        </React.Fragment>
    )
}

export default SectionEdit;